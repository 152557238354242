import { __awaiter } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
export class MsaUiStructureDownload extends React.Component {
    render() {
        return _jsx("div", { title: "Download 3D structures", onClick: () => this.click(), style: { cursor: "pointer" }, children: "EXPORT 3D" });
    }
    click() {
        return __awaiter(this, void 0, void 0, function* () {
            this.props.stateManager.next({ view: "ui-view", type: "structure-download" });
        });
    }
}
