import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { asyncScheduler } from "rxjs";
import { AbstractView } from "../AbstractView";
import uniqid from "uniqid";
import { RcsbFv } from "@rcsb/rcsb-saguaro/lib/RcsbFv/RcsbFv";
export class BlockSelectorManager {
    constructor(f) {
        this.blockChangeCallback = () => { };
        this.blockChangeCallback = f;
    }
    setActiveBlock(blockId) {
        this.previousBlockId = this.blockId;
        this.blockId = blockId;
        this.blockChangeCallback();
    }
    getActiveBlock() {
        return this.blockId;
    }
    getPreviousBlock() {
        return this.previousBlockId;
    }
}
export class CustomView extends AbstractView {
    constructor(props) {
        super(props);
        this.blockViewSelector = new BlockSelectorManager(this.blockChange.bind(this));
        this.boardMap = new Map();
        this.blockMap = new Map();
        this.rcsbFvMap = new Map();
        this.firstModelLoad = true;
        this.innerSelectionFlag = false;
        this.updateContext = null;
        this.state = {
            blockConfig: this.props.blockConfig,
            blockSelectorElement: this.props.blockSelectorElement,
            blockChangeCallback: this.props.blockChangeCallback
        };
        this.mapBlocks(props.blockConfig);
    }
    componentDidMount() {
        super.componentDidMount();
        this.blockViewSelector.setActiveBlock((this.state.blockConfig instanceof Array ? this.state.blockConfig : [this.state.blockConfig])[0].blockId);
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        this.rcsbFvMap.forEach((pfv, id) => {
            pfv.unmount();
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.updateContext != "state-change") {
            this.updateContext = "state-change";
            this.mapBlocks(this.props.blockConfig);
            this.setState({
                blockConfig: this.props.blockConfig,
                blockSelectorElement: this.props.blockSelectorElement,
                blockChangeCallback: this.props.blockChangeCallback
            });
        }
    }
    mapBlocks(config) {
        this.rcsbFvMap.forEach((pfv, id) => {
            pfv.unmount();
        });
        this.blockMap.clear();
        this.boardMap.clear();
        (config instanceof Array ? config : [config]).forEach(block => {
            if (block.blockId == null)
                block.blockId = uniqid("block_");
            if (!this.blockMap.has(block.blockId))
                this.blockMap.set(block.blockId, new Array());
            (block.featureViewConfig instanceof Array ? block.featureViewConfig : [block.featureViewConfig]).forEach(board => {
                var _a;
                if (board.boardId == null)
                    board.boardId = uniqid("board_");
                (_a = this.blockMap.get(block.blockId)) === null || _a === void 0 ? void 0 : _a.push(board.boardId);
                this.boardMap.set(board.boardId, board);
            });
        });
    }
    blockChange() {
        this.unmountBlockFv();
        this.buildBlockFv();
        asyncScheduler.schedule(() => {
            if (typeof this.state.blockChangeCallback === "function")
                this.state.blockChangeCallback(this.props.structureViewer, Array.from(this.blockMap.get(this.blockViewSelector.getActiveBlock()).values()).map(boardId => (this.rcsbFvMap.get(boardId))), this.props.stateManager);
            else
                this.structureSelectionCallback();
        }, 1000);
    }
    unmountBlockFv() {
        var _a;
        (_a = this.blockMap.get(this.blockViewSelector.getPreviousBlock())) === null || _a === void 0 ? void 0 : _a.forEach(boardId => {
            var _a;
            if (this.rcsbFvMap.get(boardId) == null)
                return;
            this.rcsbFvMap.get(boardId).unmount();
            (_a = document.getElementById("boardDiv_" + boardId)) === null || _a === void 0 ? void 0 : _a.remove();
        });
        this.rcsbFvMap.clear();
    }
    buildBlockFv() {
        var _a;
        (_a = this.blockMap.get(this.blockViewSelector.getActiveBlock())) === null || _a === void 0 ? void 0 : _a.forEach(boardId => {
            var _a, _b, _c, _d, _e, _f, _g;
            if (this.boardMap.get(boardId) == null)
                return;
            const div = document.createElement("div");
            div.setAttribute("id", "boardDiv_" + boardId);
            (_a = document.getElementById(this.componentDivId)) === null || _a === void 0 ? void 0 : _a.append(div);
            const width = (_c = (_b = window.document.getElementById(this.componentDivId)) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().width) !== null && _c !== void 0 ? _c : 0;
            const trackWidth = width - ((_e = (_d = this.boardMap.get(boardId).boardConfig) === null || _d === void 0 ? void 0 : _d.rowTitleWidth) !== null && _e !== void 0 ? _e : 190) - 55;
            const rcsbFv = new RcsbFv({
                elementId: "boardDiv_" + boardId,
                boardConfigData: Object.assign(Object.assign({ highlightHoverPosition: true, highlightHoverElement: true }, this.boardMap.get(boardId).boardConfig), { trackWidth: ((_f = this.boardMap.get(boardId).boardConfig) === null || _f === void 0 ? void 0 : _f.trackWidth) ? ((_g = this.boardMap.get(boardId).boardConfig) === null || _g === void 0 ? void 0 : _g.trackWidth) - 4 : trackWidth, selectionChangeCallback: (selection) => {
                        if (this.innerSelectionFlag)
                            return;
                        this.boardMap.get(boardId).sequenceSelectionChangeCallback(this.props.structureViewer, this.props.stateManager, selection);
                    }, highlightHoverCallback: (elements) => {
                        this.boardMap.get(boardId).sequenceHoverCallback(this.props.structureViewer, this.props.stateManager, elements);
                    }, elementClickCallback: (element) => {
                        this.boardMap.get(boardId).sequenceElementClickCallback(this.props.structureViewer, this.props.stateManager, element);
                    } }),
                rowConfigData: this.boardMap.get(boardId).rowConfig
            });
            this.rcsbFvMap.set(boardId, rcsbFv);
        });
        /*this.props.structureViewer.setSelectCallback(()=>{
           this.structureSelectionCallback();
        });*/
    }
    structureSelectionCallback() {
        var _a;
        this.innerSelectionFlag = true;
        (_a = this.blockMap.get(this.blockViewSelector.getActiveBlock())) === null || _a === void 0 ? void 0 : _a.forEach(boardId => {
            var _a;
            const pfv = this.rcsbFvMap.get(boardId);
            if (pfv == null)
                return;
            (_a = this.boardMap.get(boardId)) === null || _a === void 0 ? void 0 : _a.structureSelectionCallback(this.props.structureViewer, pfv, this.props.stateManager);
        });
        this.innerSelectionFlag = false;
    }
    structureHoverCallback() {
        var _a;
        (_a = this.blockMap.get(this.blockViewSelector.getActiveBlock())) === null || _a === void 0 ? void 0 : _a.forEach(boardId => {
            var _a;
            const pfv = this.rcsbFvMap.get(boardId);
            if (pfv == null)
                return;
            (_a = this.boardMap.get(boardId)) === null || _a === void 0 ? void 0 : _a.structureHoverCallback(this.props.structureViewer, pfv, this.props.stateManager);
        });
    }
    representationChangeCallback() {
        //TODO
    }
    additionalContent() {
        if (this.state.blockSelectorElement == null)
            return _jsx(_Fragment, {});
        return this.state.blockSelectorElement(this.blockViewSelector);
    }
    modelChangeCallback() {
        if (this.firstModelLoad) {
            this.firstModelLoad = false;
            return;
        }
        if (typeof this.props.modelChangeCallback === "function") {
            let newConfig = this.props.modelChangeCallback();
            if (newConfig != null) {
                this.updateContext = "state-change";
                if (newConfig.blockConfig != null && newConfig.blockSelectorElement != null) {
                    this.mapBlocks(newConfig.blockConfig);
                    this.setState({ blockConfig: newConfig.blockConfig, blockSelectorElement: newConfig.blockSelectorElement });
                }
                else if (newConfig.blockConfig == null && newConfig.blockSelectorElement != null) {
                    this.setState({ blockSelectorElement: newConfig.blockSelectorElement });
                }
                else if (newConfig.blockConfig != null && newConfig.blockSelectorElement == null) {
                    this.mapBlocks(newConfig.blockConfig);
                    this.setState({ blockConfig: newConfig.blockConfig });
                }
            }
        }
    }
    setState(state, callback) {
        super.setState(state, () => {
            this.blockViewSelector.setActiveBlock((this.state.blockConfig instanceof Array ? this.state.blockConfig : [this.state.blockConfig])[0].blockId);
            if (typeof callback === "function")
                callback();
            this.updateContext = null;
        });
    }
    updateDimensions() {
        var _a, _b, _c;
        const div = (_a = document.getElementById(this.componentDivId)) === null || _a === void 0 ? void 0 : _a.parentElement;
        const width = (_c = (_b = window.document.getElementById(this.componentDivId)) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().width) !== null && _c !== void 0 ? _c : 0;
        if (div == null || (div.style.width && !div.style.width.includes("%")))
            return;
        this.rcsbFvMap.forEach((rcsbFv, boardId) => {
            var _a, _b, _c, _d;
            const trackWidth = width - ((_b = (_a = this.boardMap.get(boardId).boardConfig) === null || _a === void 0 ? void 0 : _a.rowTitleWidth) !== null && _b !== void 0 ? _b : 190) - 55;
            rcsbFv.updateBoardConfig({ boardConfigData: { trackWidth: ((_c = this.boardMap.get(boardId).boardConfig) === null || _c === void 0 ? void 0 : _c.trackWidth) ? ((_d = this.boardMap.get(boardId).boardConfig) === null || _d === void 0 ? void 0 : _d.trackWidth) - 4 : trackWidth } });
        });
    }
}
