import { __awaiter } from "tslib";
import { AbstractCallbackManager } from "../CallbackManagerFactoryInterface";
import { AlignmentMapper as AM } from "../../../../Utils/AlignmentMapper";
import { TagDelimiter } from "@rcsb/rcsb-api-tools/build/RcsbUtils/TagDelimiter";
export class MsaCallbackManagerFactory {
    constructor(config) {
        this.pluginLoadParamsDefinition = config.pluginLoadParamsDefinition;
        this.alignmentResponseContainer = config.alignmentResponseContainer;
    }
    getCallbackManager(config) {
        return new MsaCallbackManager(Object.assign(Object.assign({}, config), { loadParamRequest: this.pluginLoadParamsDefinition, alignmentResponseContainer: this.alignmentResponseContainer }));
    }
}
class MsaCallbackManager extends AbstractCallbackManager {
    constructor(config) {
        super(config);
        this.targetIds = {};
        this.alignmentResponseContainer = config.alignmentResponseContainer;
    }
    featureClickCallback(e) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const alignment = yield ((_a = this.rcsbFvContainer.get()) === null || _a === void 0 ? void 0 : _a.getAlignmentResponse());
            if (alignment) {
                const regions = this.getModelRegions(e ? [e] : [], alignment, Array.from(this.stateManager.assemblyModelSate.getMap().keys()), "query");
                this.stateManager.next({ type: "feature-click", view: "1d-view", data: regions });
            }
        });
    }
    highlightHoverCallback(selection) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.select(selection, "hover");
        });
    }
    modelChangeCallback(defaultAuthId, defaultOperatorName) {
        return Promise.resolve(undefined);
    }
    pfvChangeCallback(params) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof this.rcsbFvContainer.get() === "undefined")
                return;
            const alignmentResponse = yield ((_a = this.rcsbFvContainer.get()) === null || _a === void 0 ? void 0 : _a.getAlignmentResponse());
            if (!this.alignmentResponseContainer.get() && alignmentResponse) {
                this.alignmentResponseContainer.set(alignmentResponse);
                (_b = alignmentResponse.target_alignment) === null || _b === void 0 ? void 0 : _b.forEach(ta => { if (ta === null || ta === void 0 ? void 0 : ta.target_id)
                    this.targetIds[ta.target_id] = true; });
            }
            else if (alignmentResponse) {
                const newTargetAlignments = (_c = alignmentResponse.target_alignment) === null || _c === void 0 ? void 0 : _c.filter(ta => {
                    if (ta && ta.target_id && !this.targetIds[ta.target_id]) {
                        this.targetIds[ta.target_id] = true;
                        return true;
                    }
                });
                if (newTargetAlignments && this.alignmentResponseContainer.get()) {
                    const ar = this.alignmentResponseContainer.get();
                    ar.target_alignment = (_d = ar.target_alignment) === null || _d === void 0 ? void 0 : _d.concat(newTargetAlignments);
                    this.alignmentResponseContainer.set(ar);
                }
            }
        });
    }
    innerStructureViewerSelectionChange(mode) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const allSel = this.stateManager.selectionState.getSelection(mode);
            const alignment = yield ((_a = this.rcsbFvContainer.get()) === null || _a === void 0 ? void 0 : _a.getAlignmentResponse());
            let regions = [];
            if (alignment) {
                allSel.forEach(sel => {
                    var _a;
                    const chain = (_a = this.stateManager.assemblyModelSate.getModelChainInfo(sel.modelId)) === null || _a === void 0 ? void 0 : _a.chains.find(ch => (ch.entityId == TagDelimiter.parseEntity(sel.modelId).entityId || ch.label == TagDelimiter.parseInstance(sel.modelId).instanceId) && ch.label == sel.labelAsymId);
                    if (chain) {
                        regions = regions.concat(this.getModelRegions(sel.regions.map(r => ({
                            begin: r.begin,
                            end: r.end
                        })), alignment, [sel.modelId], "target"));
                    }
                });
            }
            (_b = this.rcsbFvContainer.get()) === null || _b === void 0 ? void 0 : _b.getFv().setSelection({ mode, elements: regions.map(r => r.region) });
        });
    }
    innerPfvSelectionChange(selection) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.select(selection, "select");
        });
    }
    select(selection, mode) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const alignment = yield ((_a = this.rcsbFvContainer.get()) === null || _a === void 0 ? void 0 : _a.getAlignmentResponse());
            if (alignment) {
                const regions = this.getModelRegions(selection, alignment, Array.from((_c = (_b = this.stateManager.assemblyModelSate.getMap()) === null || _b === void 0 ? void 0 : _b.keys()) !== null && _c !== void 0 ? _c : []), "query");
                this.stateManager.selectionState.clearSelection(mode);
                if (regions.length > 0) {
                    this.stateManager.selectionState.selectFromMultipleRegions("set", regions, mode);
                }
                this.stateManager.next({ type: mode == "select" ? "selection-change" : "hover-change", view: "1d-view" });
            }
        });
    }
    getModelRegions(selection, alignment, modelList, pointer) {
        const regions = [];
        modelList.forEach(modelId => {
            var _a;
            const chain = (_a = this.stateManager.assemblyModelSate.getModelChainInfo(modelId)) === null || _a === void 0 ? void 0 : _a.chains.find(ch => ch.entityId == TagDelimiter.parseEntity(modelId).entityId || ch.label == TagDelimiter.parseInstance(modelId).instanceId);
            if (!chain)
                return;
            const labelAsymId = chain.label;
            const operatorName = chain.operators[0].name;
            if (!labelAsymId || !operatorName)
                return;
            selection.forEach(s => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                const alignedRegions = ((_c = (_b = (_a = alignment.target_alignment) === null || _a === void 0 ? void 0 : _a.find(ta => (ta === null || ta === void 0 ? void 0 : ta.target_id) === modelId)) === null || _b === void 0 ? void 0 : _b.aligned_regions.filter((o) => o != null)) !== null && _c !== void 0 ? _c : []).concat((_g = (_f = (_e = (_d = this.alignmentResponseContainer.get()) === null || _d === void 0 ? void 0 : _d.target_alignment) === null || _e === void 0 ? void 0 : _e.find(ta => (ta === null || ta === void 0 ? void 0 : ta.target_id) === modelId)) === null || _f === void 0 ? void 0 : _f.aligned_regions.filter((o) => o != null)) !== null && _g !== void 0 ? _g : []);
                if (!alignedRegions)
                    return;
                (_j = AM.mapRangeToRegionList({ begin: s.begin, end: (_h = s.end) !== null && _h !== void 0 ? _h : s.begin }, alignedRegions, pointer)) === null || _j === void 0 ? void 0 : _j.forEach(region => {
                    regions.push({
                        modelId,
                        labelAsymId,
                        operatorName,
                        region: Object.assign(Object.assign({}, region), { source: "sequence" })
                    });
                });
            });
        });
        return regions;
    }
}
