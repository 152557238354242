import { StructureElement, StructureProperties as SP, StructureQuery, StructureSelection } from "molstar/lib/mol-model/structure";
import { OrderedSet } from "molstar/lib/mol-data/int";
import { Queries as Q } from "molstar/lib/mol-model/structure/query";
export class MolstarCallbackManager {
    constructor(config) {
        this.viewer = config.viewer;
        this.stateManager = config.stateManager;
        this.loadingFlag = config.loadingFlag;
        this.modelMapManager = config.modelMapManager;
        this.innerSelectionFlag = config.innerSelectionFlag;
        this.innerReprChangeFlag = config.innerReprChangeFlag;
    }
    subscribeRepresentationChange() {
        this.reprChangeSubs = this.viewer.plugin.state.data.events.cell.stateUpdated.subscribe((s) => {
            var _a, _b, _c;
            if (this.innerReprChangeFlag.get())
                return;
            if ((_b = (_a = s.cell.obj) === null || _a === void 0 ? void 0 : _a.tags) === null || _b === void 0 ? void 0 : _b.find(t => t.indexOf('structure-component-') === 0))
                this.stateManager.next({ type: "representation-change", view: "3d-view", data: { label: (_c = s.cell.obj) === null || _c === void 0 ? void 0 : _c.label, isHidden: !!s.cell.state.isHidden } });
        });
        return this.reprChangeSubs;
    }
    subscribeHover() {
        this.hoverSubs = this.viewer.plugin.behaviors.interaction.hover.subscribe((r) => {
            var _a, _b;
            const sequenceData = new Array();
            const loci = r.current.loci;
            if (StructureElement.Loci.is(loci)) {
                const loc = StructureElement.Location.create(loci.structure);
                for (const e of loci.elements) {
                    const modelId = (_b = (_a = e.unit) === null || _a === void 0 ? void 0 : _a.model) === null || _b === void 0 ? void 0 : _b.id;
                    const seqIds = new Set();
                    loc.unit = e.unit;
                    for (let i = 0, il = OrderedSet.size(e.indices); i < il; ++i) {
                        loc.element = e.unit.elements[OrderedSet.getAt(e.indices, i)];
                        seqIds.add(SP.residue.label_seq_id(loc));
                    }
                    sequenceData.push({
                        modelId: this.modelMapManager.getModelId(modelId),
                        labelAsymId: SP.chain.label_asym_id(loc),
                        operatorName: SP.unit.operator_name(loc),
                        seqIds
                    });
                }
            }
            this.stateManager.selectionState.setSelectionFromResidueSelection(sequenceData, 'hover', 'structure');
            this.stateManager.next({ type: "hover-change", view: "3d-view" });
        });
        return this.hoverSubs;
    }
    subscribeSelection() {
        this.selectSubs = this.viewer.plugin.managers.structure.selection.events.changed.subscribe(() => {
            var _a;
            if (this.innerSelectionFlag.get())
                return;
            if (this.viewer.plugin.managers.structure.selection.additionsHistory.length > 0) {
                const currentLoci = this.viewer.plugin.managers.structure.selection.additionsHistory[0].loci;
                const loc = StructureElement.Location.create(currentLoci.structure);
                StructureElement.Location.set(loc, currentLoci.structure, currentLoci.elements[0].unit, currentLoci.elements[0].unit.elements[OrderedSet.getAt(currentLoci.elements[0].indices, 0)]);
                const currentModelId = this.modelMapManager.getModelId(currentLoci.structure.model.id);
                if (currentLoci.elements.length > 0)
                    if (SP.entity.type(loc) === 'non-polymer') {
                        const resAuthId = SP.residue.auth_seq_id(loc);
                        const chainLabelId = SP.chain.label_asym_id(loc);
                        const query = Q.modifiers.includeSurroundings(Q.generators.residues({
                            residueTest: l => SP.residue.auth_seq_id(l.element) === resAuthId,
                            chainTest: l => SP.chain.label_asym_id(l.element) === chainLabelId
                        }), {
                            radius: 5,
                            wholeResidues: true
                        });
                        this.innerSelectionFlag.set(true);
                        const sel = StructureQuery.run(query, currentLoci.structure);
                        const surroundingsLoci = StructureSelection.toLociWithSourceUnits(sel);
                        this.viewer.plugin.managers.structure.selection.fromLoci('add', surroundingsLoci);
                        const surroundingsLoc = StructureElement.Location.create(surroundingsLoci.structure);
                        for (const e of surroundingsLoci.elements) {
                            StructureElement.Location.set(surroundingsLoc, surroundingsLoci.structure, e.unit, e.unit.elements[0]);
                            if (SP.entity.type(surroundingsLoc) === 'polymer') {
                                this.stateManager.selectionState.setLastSelection({
                                    modelId: currentModelId,
                                    labelAsymId: SP.chain.label_asym_id(surroundingsLoc),
                                    source: "structure",
                                    regions: []
                                });
                            }
                        }
                        this.innerSelectionFlag.set(false);
                    }
                    else if (SP.entity.type(loc) === 'polymer') {
                        this.stateManager.selectionState.setLastSelection({
                            modelId: currentModelId,
                            labelAsymId: SP.chain.label_asym_id(loc),
                            operatorName: SP.unit.operator_name(loc),
                            source: "structure",
                            regions: []
                        });
                    }
                    else {
                        this.stateManager.selectionState.setLastSelection(null);
                    }
                else
                    this.stateManager.selectionState.setLastSelection(null);
            }
            else {
                this.stateManager.selectionState.setLastSelection(null);
            }
            const sequenceData = new Array();
            for (const structure of this.viewer.plugin.managers.structure.hierarchy.current.structures) {
                const data = (_a = structure.cell.obj) === null || _a === void 0 ? void 0 : _a.data;
                if (data == null)
                    return;
                const loci = this.viewer.plugin.managers.structure.selection.getLoci(data);
                if (StructureElement.Loci.is(loci)) {
                    const loc = StructureElement.Location.create(loci.structure);
                    for (const e of loci.elements) {
                        StructureElement.Location.set(loc, loci.structure, e.unit, e.unit.elements[0]);
                        if (SP.entity.type(loc) === 'polymer') {
                            const seqIds = new Set();
                            for (let i = 0, il = OrderedSet.size(e.indices); i < il; ++i) {
                                loc.element = e.unit.elements[OrderedSet.getAt(e.indices, i)];
                                seqIds.add(SP.residue.label_seq_id(loc));
                            }
                            if (seqIds.size > 0)
                                sequenceData.push({
                                    modelId: this.modelMapManager.getModelId(data.model.id),
                                    labelAsymId: SP.chain.label_asym_id(loc),
                                    operatorName: SP.unit.operator_name(loc),
                                    seqIds
                                });
                        }
                    }
                }
            }
            this.stateManager.selectionState.setSelectionFromResidueSelection(sequenceData, 'select', 'structure');
            if (sequenceData.length == 0)
                this.stateManager.selectionState.setLastSelection(null);
            this.stateManager.next({ type: "selection-change", view: "3d-view" });
        });
        return this.selectSubs;
    }
    pluginCall(f) {
        this.viewer.pluginCall(f);
    }
    subscribeModelChange() {
        this.modelChangeSubs = this.viewer.plugin.state.behaviors.events.object.updated.subscribe(o => {
            if (this.loadingFlag.get())
                return;
            this.modelChange();
        });
        return this.modelChangeSubs;
    }
    modelChange() {
        this.stateManager.assemblyModelSate.setMap(this.modelMapManager.getChains());
        this.stateManager.next({ type: "model-change", view: "3d-view" });
    }
    unsubscribe() {
        var _a, _b, _c;
        (_a = this.selectSubs) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        (_b = this.modelChangeSubs) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        (_c = this.hoverSubs) === null || _c === void 0 ? void 0 : _c.unsubscribe();
    }
}
