import { Subject } from 'rxjs';
/**Event types*/
export var EventType;
(function (EventType) {
    EventType["UPDATE_CONFIG"] = "updateBoardConfig";
    EventType["PLUGIN_CALL"] = "pluginCall";
})(EventType || (EventType = {}));
/**rxjs Event Handler Object. It allows objects to subscribe methods and then, get(send) events to(from) other objects*/
export class RcsbFvContextManager {
    constructor() {
        this.subject = new Subject();
    }
    /**Call other subscribed methods
     * @param obj Event Data Structure Interface
     * */
    next(obj) {
        this.subject.next(obj);
    }
    /**Subscribe loadMethod
     * @return Subscription
     * */
    subscribe(f) {
        return this.subject.asObservable().subscribe(f);
    }
    /**Unsubscribe all methods*/
    unsubscribeAll() {
        this.subject.unsubscribe();
    }
}
