//TODO this class should be interfaced
//TODO Check how lastSelection is used. It is not linked to selection. Only label asymId is used when the value is got
export class RcsbFvSelectorManager {
    constructor() {
        this.lastSelection = null;
        this.selection = new Array();
        this.hover = new Array();
    }
    selectFromRegion(operation, modelId, labelAsymId, region, mode, operatorName) {
        if (operation == "set")
            this.clearSelection(mode, { modelId, labelAsymId, operatorName });
        this.addSelectionFromRegion(modelId, labelAsymId, region, mode, operatorName);
    }
    addSelectionFromRegion(modelId, labelAsymId, region, mode, operatorName) {
        if (mode === 'select') {
            this.selection.push({ modelId: modelId, labelAsymId: labelAsymId, regions: [region], operatorName: operatorName });
        }
        else {
            this.hover.push({ modelId: modelId, labelAsymId: labelAsymId, regions: [region], operatorName: operatorName });
        }
    }
    selectFromMultipleRegions(operation, regions, mode) {
        if (operation == "set")
            regions.forEach(r => {
                this.clearSelection(mode, r);
            });
        this.addSelectionFromMultipleRegions(regions, mode);
    }
    addSelectionFromMultipleRegions(regions, mode) {
        regions.forEach(r => {
            this.addSelectionFromRegion(r.modelId, r.labelAsymId, r.region, mode, r.operatorName);
        });
    }
    setSelectionFromResidueSelection(res, mode, source) {
        if (mode === 'select') {
            this.selection = selectionFromResidueSelection(res, mode, source);
        }
        else {
            this.hover = selectionFromResidueSelection(res, mode, source);
        }
    }
    getSelection(mode) {
        if (mode === 'select')
            return this.selection;
        else
            return this.hover;
    }
    getLastSelection() {
        return this.lastSelection;
    }
    setLastSelection(selection) {
        this.lastSelection = selection;
    }
    getSelectionWithCondition(modelId, labelAsymId, mode, operatorName) {
        const sel = mode === 'select' ?
            this.selection.filter(d => (d.modelId === modelId && d.labelAsymId === labelAsymId && (d.operatorName === operatorName || !operatorName))) :
            this.hover.filter(d => (d.modelId === modelId && d.labelAsymId === labelAsymId && (d.operatorName === operatorName || !operatorName)));
        if (sel.length > 0)
            return { modelId: sel[0].modelId, labelAsymId: sel[0].labelAsymId, operatorName: operatorName, regions: [].concat.apply([], sel.map(s => s.regions)) };
    }
    clearSelection(mode, selection) {
        if (!selection)
            if (mode === 'select')
                this.selection = new Array();
            else
                this.hover = new Array();
        else if (selection.labelAsymId || selection.modelId)
            if (mode === 'select')
                this.selection = this.selection.filter(r => selectionFilter(r, selection));
            else
                this.hover = this.hover.filter(r => selectionFilter(r, selection));
    }
    //TODO missing operatorName case
    selectionSource(mode, region) {
        var _a, _b, _c, _d;
        if (mode === 'select')
            return (_b = (_a = this.selection
                .filter(r => (r.modelId === region.modelId && r.labelAsymId === region.labelAsymId))[0]) === null || _a === void 0 ? void 0 : _a.regions.filter(r => (r.begin === region.begin && r.end === region.end))[0]) === null || _b === void 0 ? void 0 : _b.source;
        else
            return (_d = (_c = this.hover
                .filter(r => (r.modelId === region.modelId && r.labelAsymId === region.labelAsymId))[0]) === null || _c === void 0 ? void 0 : _c.regions.filter(r => (r.begin === region.begin && r.end === region.end))[0]) === null || _d === void 0 ? void 0 : _d.source;
    }
}
function selectionFromResidueSelection(res, mode, source) {
    const none = "none";
    const selMap = new Map();
    res.forEach(r => {
        if (!selMap.has(r.modelId))
            selMap.set(r.modelId, new Map());
        if (!selMap.get(r.modelId).has(r.labelAsymId))
            selMap.get(r.modelId).set(r.labelAsymId, new Map());
        if (r.operatorName && !selMap.get(r.modelId).get(r.labelAsymId).has(r.operatorName))
            selMap.get(r.modelId).get(r.labelAsymId).set(r.operatorName, new Set());
        else
            selMap.get(r.modelId).get(r.labelAsymId).set(none, new Set());
        r.seqIds.forEach(s => {
            var _a;
            selMap.get(r.modelId).get(r.labelAsymId).get((_a = r.operatorName) !== null && _a !== void 0 ? _a : none).add(s);
        });
    });
    const selection = new Array();
    selMap.forEach((labelMap, modelId) => {
        labelMap.forEach((operatorMap, labelId) => {
            operatorMap.forEach((seqSet, operatorId) => {
                selection.push({
                    modelId: modelId,
                    labelAsymId: labelId,
                    operatorName: operatorId != none ? operatorId : undefined,
                    regions: buildIntervals(seqSet, source)
                });
            });
        });
    });
    return selection;
}
function buildIntervals(ids, source) {
    const out = new Array();
    const sorted = Array.from(ids).sort((a, b) => (a - b));
    let begin = sorted.shift();
    let end = begin;
    for (const n of sorted) {
        if (n == (end + 1)) {
            end = n;
        }
        else {
            out.push({ begin: begin, end: end, source: source });
            begin = n;
            end = n;
        }
    }
    out.push({ begin: begin, end: end, source: source });
    return out;
}
function selectionFilter(r, selection) {
    return (typeof selection.modelId === "string" && r.modelId != selection.modelId) ||
        (typeof selection.labelAsymId === "string" && r.labelAsymId != selection.labelAsymId) ||
        (typeof selection.operatorName === "string" && r.operatorName != selection.operatorName);
}
