import { __awaiter } from "tslib";
export class StructureViewer {
    constructor(structureViewerManagerFactory) {
        this.structureViewerManagerFactory = structureViewerManagerFactory;
    }
    init(stateManager, args) {
        const { actionManager, callbackManager } = this.structureViewerManagerFactory.getViewerManagerFactory(stateManager, args);
        this.actionManager = actionManager;
        this.callbackManager = callbackManager;
        this.subscribeSelection();
        this.subscribeHover();
        this.subscribeRepresentationChange();
        this.subscribeModelChange();
    }
    clear() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.actionManager.clear();
        });
    }
    load(loadConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            const out = yield this.actionManager.load(Array.isArray(loadConfig) ? loadConfig : [loadConfig]);
            this.modelChange();
            return out;
        });
    }
    removeStructure(loadConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.actionManager.removeStructure(loadConfig);
            this.modelChange();
        });
    }
    setBackground(color) {
    }
    select(...args) {
        this.actionManager.select(args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
    }
    clearSelection(mode, option) {
        this.actionManager.clearSelection(mode, option);
    }
    setFocus(modelId, labelAsymId, begin, end, operatorName) {
        this.actionManager.setFocus(modelId, labelAsymId, begin, end, operatorName);
    }
    clearFocus() {
        this.actionManager.clearFocus();
    }
    cameraFocus(...args) {
        this.actionManager.cameraFocus(args[0], args[1], args[2], args[3], args[4]);
    }
    createComponent(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.actionManager.createComponent(args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
        });
    }
    isComponent(componentLabel) {
        return this.actionManager.isComponent(componentLabel);
    }
    colorComponent(componentLabel, color) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.actionManager.colorComponent(componentLabel, color);
        });
    }
    getComponentSet() {
        return this.actionManager.getComponentSet();
    }
    removeComponent(componentLabel) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.actionManager.removeComponent(componentLabel);
        });
    }
    displayComponent(componentLabel, visibilityFlag) {
        return this.actionManager.displayComponent(componentLabel, visibilityFlag);
    }
    subscribeRepresentationChange() {
        return this.callbackManager.subscribeRepresentationChange();
    }
    subscribeHover() {
        return this.callbackManager.subscribeHover();
    }
    subscribeSelection() {
        return this.callbackManager.subscribeSelection();
    }
    pluginCall(f) {
        this.callbackManager.pluginCall(f);
    }
    subscribeModelChange() {
        return this.callbackManager.subscribeModelChange();
    }
    modelChange() {
        this.callbackManager.modelChange();
    }
    unsubscribe() {
        this.callbackManager.unsubscribe();
    }
    resetCamera() {
        this.actionManager.resetCamera();
    }
    exportLoadedStructures() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.actionManager.exportLoadedStructures();
        });
    }
}
