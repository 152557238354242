export var RcsbFvDOMConstants;
(function (RcsbFvDOMConstants) {
    RcsbFvDOMConstants["SELECT_BUTTON_PFV_ID"] = "selectButtonPfv";
    RcsbFvDOMConstants["PFV_APP_ID"] = "pfvApp";
    RcsbFvDOMConstants["PFV_DIV"] = "pfvDiv";
    RcsbFvDOMConstants["MOLSTAR_DIV"] = "molstarDiv";
    RcsbFvDOMConstants["MOLSTAR_APP_ID"] = "molstarApp";
    RcsbFvDOMConstants["TITLE_ID"] = "titleDivId";
    RcsbFvDOMConstants["SUBTITLE_ID"] = "subtitleDiv";
    RcsbFvDOMConstants["CLOSE_ID"] = "closeDiv";
})(RcsbFvDOMConstants || (RcsbFvDOMConstants = {}));
