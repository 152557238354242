export var FunctionCall;
(function (FunctionCall) {
    function onetimeCall(f) {
        const g = {
            onetime: (x) => {
                f(x);
                g.onetime = (x) => { };
            }
        };
        return (x) => {
            g.onetime(x);
        };
    }
    FunctionCall.onetimeCall = onetimeCall;
})(FunctionCall || (FunctionCall = {}));
