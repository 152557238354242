import { __awaiter } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { asyncScheduler } from "rxjs";
import { RcsbFvDOMConstants } from "../../../../RcsbFvConstants/RcsbFvConstants";
import { ChainDisplayComponent } from "./AssemblyPfvComponents/ChainDisplayComponent";
import { Source, Type } from "@rcsb/rcsb-api-tools/build/RcsbGraphQL/Types/Borrego/GqlTypes";
import { DataContainer } from "../../../../Utils/DataContainer";
import { AbstractPfvManager } from "../PfvManagerFactoryInterface";
import { buildInstanceSequenceFv } from "@rcsb/rcsb-saguaro-app/lib/RcsbFvWeb/RcsbFvBuilder";
import { RcsbFvUI } from "@rcsb/rcsb-saguaro-app/lib/RcsbExport/RcsbFvUI";
import { FeatureType, RcsbRequestContextManager } from "@rcsb/rcsb-saguaro-app/lib/app";
export class AssemblyPfvManagerFactory {
    getPfvManager(config) {
        return new AssemblyPfvManager(config);
    }
}
class AssemblyPfvManager extends AbstractPfvManager {
    constructor(config) {
        super(config);
        this.OPERATOR_DROPDOWN_TITLE = "Symmetry Partner";
        this.module = undefined;
        this.instanceSequenceConfig = config.instanceSequenceConfig;
        this.useOperatorsFlag = config.useOperatorsFlag;
    }
    create(config) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const onChangeCallback = new Map();
            const assemblyInstances = new Map();
            this.stateManager.assemblyModelSate.forEach((v, k) => {
                assemblyInstances.set(v.entryId, new Set(v.chains.map(d => d.label)));
                onChangeCallback.set(v.entryId, (x) => {
                    this.stateManager.assemblyModelSate.set({ entryId: v.entryId, labelAsymId: x.asymId, modelId: k });
                    asyncScheduler.schedule(() => {
                        this.pfvChangeCallback(undefined);
                    }, 100);
                });
            });
            const operatorNameContainer = new DataContainer(config.defaultOperatorName);
            if (this.stateManager.assemblyModelSate.get("entryId") != null) {
                this.module = yield buildInstanceSequenceFv(this.rcsbFvDivId, RcsbFvDOMConstants.SELECT_BUTTON_PFV_ID, this.stateManager.assemblyModelSate.getString("entryId"), Object.assign(Object.assign({}, this.instanceSequenceConfig), { defaultValue: (_a = config.defaultAuthId) !== null && _a !== void 0 ? _a : (_b = this.instanceSequenceConfig) === null || _b === void 0 ? void 0 : _b.defaultValue, onChangeCallback: (context, module) => {
                        var _a, _b, _c;
                        (_a = onChangeCallback.get(this.stateManager.assemblyModelSate.getString("entryId"))) === null || _a === void 0 ? void 0 : _a(context);
                        const entryMap = Array.from(this.stateManager.assemblyModelSate.entries()).find((e) => (e[1].entryId === context.entryId));
                        const operator = entryMap && entryMap[0] ? getOperator(this.stateManager.assemblyModelSate.getMap().get(entryMap[0]), config.defaultAuthId, operatorNameContainer.get()) : undefined;
                        this.stateManager.pfvContext.set(Object.assign(Object.assign({}, context), { operator }));
                        (_c = (_b = this.instanceSequenceConfig) === null || _b === void 0 ? void 0 : _b.onChangeCallback) === null || _c === void 0 ? void 0 : _c.call(_b, context, module);
                    }, beforeChangeCallback: (x) => {
                        var _a, _b, _c, _d;
                        this.stateManager.assemblyModelSate.set({ entryId: x.entryId, labelAsymId: x.asymId });
                        const entryMap = Array.from(this.stateManager.assemblyModelSate.entries()).find((e) => (e[1].entryId === x.entryId));
                        if (!entryMap) {
                            throw `Error: no modelId was found for ${x.entryId}`;
                        }
                        const operator = getOperator(this.stateManager.assemblyModelSate.getMap().get(entryMap[0]), config.defaultAuthId, operatorNameContainer.get());
                        this.addOperatorButton(operator === null || operator === void 0 ? void 0 : operator.name);
                        this.stateManager.assemblyModelSate.setOperator(x.asymId, operator === null || operator === void 0 ? void 0 : operator.name);
                        operatorNameContainer.set(undefined);
                        if (typeof ((_a = this.additionalConfig) === null || _a === void 0 ? void 0 : _a.operatorChangeCallback) === "function" && this.stateManager.assemblyModelSate.getOperator()) {
                            this.additionalConfig.operatorChangeCallback(this.stateManager.assemblyModelSate.getOperator());
                        }
                        if (((_d = (_c = (_b = this.stateManager.assemblyModelSate.getChainInfo()) === null || _b === void 0 ? void 0 : _b.operators) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) > 1)
                            return {
                                operatorIds: operator === null || operator === void 0 ? void 0 : operator.ids
                            };
                    }, filterInstances: assemblyInstances.get(this.stateManager.assemblyModelSate.getString("entryId")), selectButtonOptionProps: (props) => (_jsxs("div", { style: { display: 'flex' }, children: [_jsx(ChainDisplayComponent, { stateManager: this.stateManager, label: props.data.label }), props.children] })) }), Object.assign(Object.assign({}, this.additionalConfig), { boardConfig: this.boardConfigContainer.get(), externalTrackBuilder: {
                        filterFeatures: this.filterFeatures.bind(this)
                    } }));
            }
            return this.module;
        });
    }
    addOperatorButton(operatorName) {
        var _a;
        const currentChainInfo = this.stateManager.assemblyModelSate.getChainInfo();
        if (this.useOperatorsFlag && currentChainInfo && currentChainInfo.operators.length > 1) {
            this.stateManager.assemblyModelSate.setOperator(undefined, operatorName);
            RcsbFvUI.addSelectButton(this.rcsbFvDivId, RcsbFvDOMConstants.SELECT_BUTTON_PFV_ID, currentChainInfo.operators.map(op => ({
                label: `${op.ids.join("-")} (${op.name})`,
                optId: op.name,
                onChange: () => __awaiter(this, void 0, void 0, function* () {
                    var _b, _c, _d;
                    (_c = (_b = this.module) === null || _b === void 0 ? void 0 : _b.getFv()) === null || _c === void 0 ? void 0 : _c.reset();
                    this.stateManager.assemblyModelSate.set({ operator: op });
                    yield this.create({
                        defaultAuthId: (_d = this.stateManager.assemblyModelSate.getChainInfo()) === null || _d === void 0 ? void 0 : _d.auth,
                        defaultOperatorName: op.name
                    });
                })
            })), {
                defaultValue: (_a = this.stateManager.assemblyModelSate.getOperator()) === null || _a === void 0 ? void 0 : _a.name,
                dropdownTitle: this.OPERATOR_DROPDOWN_TITLE
            });
        }
        else {
            RcsbFvUI.clearSelectButton(this.rcsbFvDivId, RcsbFvDOMConstants.SELECT_BUTTON_PFV_ID);
        }
    }
    filterFeatures(data) {
        return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
            let annotations = [];
            (yield Promise.all(data.annotations.map((ann) => __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c, _d;
                if (ann.source == Source.PdbInterface && ann.target_id && ((_a = data.rcsbContext) === null || _a === void 0 ? void 0 : _a.asymId)) {
                    const interfaceToInstance = yield RcsbRequestContextManager.getInterfaceToInstance(ann.target_id);
                    if (typeof ((_b = ann.target_identifiers) === null || _b === void 0 ? void 0 : _b.interface_partner_index) === "number" && ann.target_identifiers.assembly_id === this.stateManager.assemblyModelSate.getString("assemblyId") && Array.isArray(interfaceToInstance.getOperatorIds(ann.target_id))) {
                        const operatorIds = (_c = interfaceToInstance.getOperatorIds(ann.target_id)) === null || _c === void 0 ? void 0 : _c[ann.target_identifiers.interface_partner_index];
                        if (ann.features && this.stateManager.assemblyModelSate.getOperator() && (operatorIds === null || operatorIds === void 0 ? void 0 : operatorIds.map(o => o.join("|")).includes(this.stateManager.assemblyModelSate.getOperator().ids.join("|")))) {
                            ann.features = ann.features.filter(f => (f && f.type == FeatureType.BurialFraction));
                            if (ann.features.length > 0)
                                return ann;
                        }
                    }
                }
                else if (ann.source == Source.PdbInstance && ann.features) {
                    ann.features = (_d = ann.features) === null || _d === void 0 ? void 0 : _d.filter(f => ((f === null || f === void 0 ? void 0 : f.type) !== Type.Asa));
                    return ann;
                }
                else if (ann.source != Source.PdbInterface) {
                    return ann;
                }
            })))).forEach((value, index, array) => {
                if (value)
                    annotations = annotations.concat(value);
            });
            resolve(annotations);
        }));
    }
}
function getOperator(entryInfo, defaultAuthId, defaultOperatorName) {
    const chainInfo = defaultAuthId ? entryInfo.chains.find(ch => ch.auth === defaultAuthId) : entryInfo.chains[0];
    if (chainInfo) {
        const operatorInfo = defaultOperatorName ? chainInfo.operators.find(op => op.name === defaultOperatorName) : chainInfo.operators[0];
        if (operatorInfo)
            return operatorInfo;
    }
    return undefined;
}
