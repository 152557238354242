export class AssemblyModelSate {
    constructor(modelMap) {
        this.state = {};
        if (modelMap)
            this.modelMap = modelMap;
    }
    setMap(modelMap) {
        this.modelMap = modelMap;
        this.setFirstModel();
    }
    getMap() {
        return this.modelMap;
    }
    set(state) {
        this.state = Object.assign(Object.assign({}, this.state), state);
    }
    get(key) {
        return this.state[key];
    }
    getString(key) {
        if (!this.state[key])
            throw `${key} is undefined`;
        return this.state[key];
    }
    getOperator() {
        return this.state.operator;
    }
    forEach(f) {
        this.modelMap.forEach((v, k) => f(v, k));
    }
    entries() {
        return this.modelMap.entries();
    }
    setOperator(asymId, opName) {
        const currentChainInfo = this.getChainInfo(asymId !== null && asymId !== void 0 ? asymId : this.state.labelAsymId);
        this.state.operator = opName ? currentChainInfo === null || currentChainInfo === void 0 ? void 0 : currentChainInfo.operators.filter(op => (op.name === opName))[0] : currentChainInfo === null || currentChainInfo === void 0 ? void 0 : currentChainInfo.operators[0];
    }
    getModelChainInfo(modelId) {
        var _a;
        return (_a = this.modelMap) === null || _a === void 0 ? void 0 : _a.get(modelId);
    }
    getChainInfo(asymId) {
        var _a, _b;
        if (!this.state.modelId)
            throw "modelId not define";
        if (asymId)
            return (_a = this.modelMap.get(this.state.modelId)) === null || _a === void 0 ? void 0 : _a.chains.find(ch => ch.label === asymId);
        else
            return (_b = this.modelMap.get(this.state.modelId)) === null || _b === void 0 ? void 0 : _b.chains.find(ch => ch.label === this.state.labelAsymId);
    }
    setFirstModel() {
        if (Array.from(this.modelMap.keys()).length == 0) {
            this.state = {};
        }
        else {
            this.state.modelId = Array.from(this.modelMap.keys())[0];
            this.state.entryId = this.modelMap.get(this.state.modelId).entryId;
            this.state.assemblyId = this.modelMap.get(this.state.modelId).assemblyId;
            this.state.operator = undefined;
        }
    }
}
