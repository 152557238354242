import { __awaiter } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { download, getFullDate, textToFile } from "../../../../../Utils/Download";
export class MsaUiSequenceAlignmentDownload extends React.Component {
    render() {
        return _jsx("div", { title: "Download MSA of selected sequences", onClick: () => this.click(), style: { cursor: "pointer" }, children: "EXPORT MSA" });
    }
    click() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const targetAlignments = yield ((_a = this.props.rcsbFvContainer.get()) === null || _a === void 0 ? void 0 : _a.getAlignmentResponse());
            const targets = (_b = targetAlignments === null || targetAlignments === void 0 ? void 0 : targetAlignments.target_alignment) === null || _b === void 0 ? void 0 : _b.map(ta => ta === null || ta === void 0 ? void 0 : ta.target_id);
            if (!targets)
                return;
            const length = (_c = this.props.rcsbFvContainer.get()) === null || _c === void 0 ? void 0 : _c.getFv().getBoardConfig().length;
            if (!length)
                return;
            const msa = [];
            (_d = targetAlignments === null || targetAlignments === void 0 ? void 0 : targetAlignments.target_alignment) === null || _d === void 0 ? void 0 : _d.forEach(ta => {
                var _a, _b, _c, _d, _e;
                if (ta && this.props.stateManager.assemblyModelSate.getMap().has((_a = ta.target_id) !== null && _a !== void 0 ? _a : "none")) {
                    const sequence = ta.target_sequence;
                    if (!sequence)
                        return;
                    if (!ta.aligned_regions)
                        return;
                    msa.push(`>${ta.target_id}|${(_b = ta.aligned_regions[0]) === null || _b === void 0 ? void 0 : _b.query_begin}\n`);
                    const sequenceAlignment = [];
                    let prev = (_d = (_c = ta.aligned_regions[0]) === null || _c === void 0 ? void 0 : _c.query_begin) !== null && _d !== void 0 ? _d : Number.MIN_SAFE_INTEGER;
                    if (prev > 1)
                        sequenceAlignment.push("-".repeat(prev - 1));
                    for (const ar of (_e = ta.aligned_regions) !== null && _e !== void 0 ? _e : []) {
                        if (!ar)
                            continue;
                        if (ar.query_begin > prev) {
                            sequenceAlignment.push("-".repeat(ar.query_begin - prev - 1));
                        }
                        sequenceAlignment.push(sequence.substring(ar.target_begin - 1, ar.target_end));
                        prev = ar.query_end;
                    }
                    if (prev < length)
                        sequenceAlignment.push("-".repeat(length - prev));
                    msa.push(`${sequenceAlignment.join("")}\n`);
                }
            });
            download(textToFile(msa), `sequence_alignment_${getFullDate()}.fasta`);
        });
    }
}
