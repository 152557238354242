import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import classes from '../../scss/RcsbFvStyle.module.scss';
import { asyncScheduler } from "rxjs";
import { RcsbFvDOMConstants } from "../../RcsbFvConstants/RcsbFvConstants";
export class AbstractView extends React.Component {
    constructor(props) {
        super(props);
        this.updateDimTask = null;
        this.resizeCallback = () => {
            if (this.updateDimTask)
                this.updateDimTask.unsubscribe();
            this.updateDimTask = asyncScheduler.schedule(() => {
                this.updateDimensions();
            }, 300);
        };
        this.componentDivId = props.componentId + "_" + RcsbFvDOMConstants.PFV_DIV;
        this.rcsbFvDivId = props.componentId + "_" + RcsbFvDOMConstants.PFV_APP_ID;
    }
    render() {
        return (_jsxs("div", { id: this.componentDivId, children: [_jsxs("div", { style: { paddingLeft: 10, position: "relative" }, children: [this.createTitle(), this.createSubtitle(), this.additionalContent()] }), _jsx("div", { id: this.rcsbFvDivId })] }));
    }
    componentDidMount() {
        this.props.stateManager.subscribe(o => {
            if (o.view == "3d-view" && o.type == "selection-change")
                this.structureSelectionCallback();
            if (o.view == "3d-view" && o.type == "hover-change")
                this.structureHoverCallback();
            if (o.view == "3d-view" && o.type == "model-change")
                this.modelChangeCallback();
            if (o.view == "3d-view" && o.type == "representation-change")
                this.representationChangeCallback();
        });
        window.addEventListener('resize', this.resizeCallback);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeCallback);
    }
    createTitle() {
        if (this.props.title)
            return (_jsx("div", { id: RcsbFvDOMConstants.TITLE_ID, className: classes.rcsbFvTitle, children: this.props.title }));
        return null;
    }
    createSubtitle() {
        if (this.props.subtitle)
            return (_jsx("div", { id: RcsbFvDOMConstants.SUBTITLE_ID, className: classes.rcsbFvSubtitle, children: this.props.subtitle }));
        return null;
    }
}
