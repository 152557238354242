import { __awaiter } from "tslib";
import { AbstractPfvManager } from "../PfvManagerFactoryInterface";
import { MsaRowTitleComponent } from "./MsaPfvComponents/MsaRowTitleComponent";
import { MsaRowMarkComponent } from "./MsaPfvComponents/MsaRowMarkComponent";
import { MsaUiSortComponent } from "./MsaPfvComponents/MsaUiSortComponent";
import { MsaUiSequenceAlignmentDownload } from "./MsaPfvComponents/MsaUiSequenceAlignmentDownload";
import { MsaUiStructureDownload } from "./MsaPfvComponents/MsaUiStructureDownload";
import { parseEntityOrInstance } from "../../../../Utils/RcsbIdParser";
export class MsaPfvManagerFactory {
    getPfvManager(config) {
        return new MsaPfvManager(config);
    }
}
class MsaPfvManager extends AbstractPfvManager {
    constructor(config) {
        super(config);
        this.config = config;
    }
    create() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const args = [this.rcsbFvDivId, ...this.config.pfvArgs, Object.assign(Object.assign({}, this.additionalConfig), { boardConfig: this.boardConfigContainer.get(), externalTrackBuilder: {
                        filterAlignments: (data) => {
                            var _a, _b, _c, _d;
                            const visAlignment = (_c = (_b = (_a = this.config.alignmentResponseContainer) === null || _a === void 0 ? void 0 : _a.get()) === null || _b === void 0 ? void 0 : _b.target_alignment) === null || _c === void 0 ? void 0 : _c.filter(ta => { var _a; return (ta === null || ta === void 0 ? void 0 : ta.target_id) && ((_a = this.config.stateManager.assemblyModelSate.getMap()) === null || _a === void 0 ? void 0 : _a.has(ta.target_id)); });
                            const otherAlignment = (_d = data.alignments.target_alignment) === null || _d === void 0 ? void 0 : _d.filter(ta => { var _a; return (ta === null || ta === void 0 ? void 0 : ta.target_id) && !((_a = this.config.stateManager.assemblyModelSate.getMap()) === null || _a === void 0 ? void 0 : _a.has(ta.target_id)); });
                            return new Promise(resolve => resolve(Object.assign(Object.assign({}, data.alignments), { target_alignment: (visAlignment !== null && visAlignment !== void 0 ? visAlignment : []).concat(otherAlignment !== null && otherAlignment !== void 0 ? otherAlignment : []) })));
                        }
                    }, trackConfigModifier: {
                        alignment: (alignmentContext, targetAlignment, alignmentResponse, alignmentIndex) => new Promise((resolve) => {
                            var _a, _b;
                            const alignmentMod = {
                                rowMark: {
                                    externalRowMark: {
                                        component: MsaRowMarkComponent,
                                        props: {
                                            rowRef: parseEntityOrInstance(targetAlignment.target_id),
                                            stateManager: this.stateManager
                                        }
                                    },
                                    clickCallback: () => this.loadAlignment(alignmentContext, targetAlignment)
                                },
                                externalRowTitle: {
                                    rowTitleComponent: MsaRowTitleComponent,
                                    rowTitleAdditionalProps: {
                                        alignmentContext,
                                        targetAlignment,
                                        stateManager: this.stateManager,
                                        titleClick: () => this.loadAlignment(alignmentContext, targetAlignment)
                                    }
                                },
                                metadata: {
                                    targetId: targetAlignment.target_id
                                }
                            };
                            if ((_b = (_a = this.additionalConfig) === null || _a === void 0 ? void 0 : _a.trackConfigModifier) === null || _b === void 0 ? void 0 : _b.alignment)
                                this.additionalConfig.trackConfigModifier.alignment(alignmentContext, targetAlignment, alignmentResponse, alignmentIndex).then((rc) => {
                                    resolve(Object.assign(Object.assign({}, rc), alignmentMod));
                                });
                            else
                                resolve(alignmentMod);
                        })
                    }, beforeChangeCallback: () => {
                        this.config.pfvChangeCallback({ context: { id: this.config.id } });
                    }, externalUiComponents: ((_b = (_a = this.additionalConfig) === null || _a === void 0 ? void 0 : _a.externalUiComponents) === null || _b === void 0 ? void 0 : _b.replace) ? {
                        replace: (_d = (_c = this.additionalConfig) === null || _c === void 0 ? void 0 : _c.externalUiComponents) === null || _d === void 0 ? void 0 : _d.replace
                    } : {
                        add: [{
                                component: MsaUiSortComponent,
                                props: {
                                    rcsbFvContainer: this.rcsbFvContainer,
                                    stateManager: this.stateManager
                                }
                            }, {
                                component: MsaUiSequenceAlignmentDownload,
                                props: {
                                    rcsbFvContainer: this.rcsbFvContainer,
                                    stateManager: this.stateManager
                                }
                            }, {
                                component: MsaUiStructureDownload,
                                props: {
                                    stateManager: this.stateManager
                                }
                            }]
                    } })];
            this.module = yield this.config.buildMsaAlignmentFv(...args);
            this.rcsbFvContainer.set(this.module);
            yield this.readyStateLoad();
            return this.module;
        });
    }
    readyStateLoad() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const alignments = yield this.rcsbFvContainer.get().getAlignmentResponse();
            if (alignments.target_alignment && alignments.target_alignment.length > 0 && typeof ((_a = alignments.target_alignment[0]) === null || _a === void 0 ? void 0 : _a.target_id) === "string") {
                this.loadAlignment({ queryId: this.config.id }, alignments.target_alignment[0], "auto");
            }
        });
    }
    loadAlignment(alignmentContext, targetAlignment, who = "user") {
        if (typeof targetAlignment.target_id === "string") {
            this.stateManager.next({
                type: "model-change",
                view: "1d-view",
                data: {
                    pdb: parseEntityOrInstance(targetAlignment.target_id),
                    targetAlignment,
                    who
                }
            });
        }
    }
}
