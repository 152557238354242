import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import classes from '../scss/RcsbFvStyle.module.scss';
import '../scss/RcsbFvMolstarStyle.module.scss';
import { RcsbFvSequence } from "../RcsbFvSequence/RcsbFvSequence";
import { RcsbFvStructure } from "../RcsbFvStructure/RcsbFvStructure";
import { EventType } from "../RcsbFvContextManager/RcsbFvContextManager";
import { RcsbFvStateManager } from "../RcsbFvState/RcsbFvStateManager";
export class RcsbFv3DComponent extends React.Component {
    constructor() {
        super(...arguments);
        this.stateManager = new RcsbFvStateManager();
        this.ROOT_DIV_ID = "rootPanelDiv";
        this.state = {
            structurePanelConfig: this.props.structurePanelConfig,
            sequencePanelConfig: this.props.sequencePanelConfig,
            pfvScreenFraction: 0.55
        };
        this.resize = null;
    }
    render() {
        var _a, _b, _c;
        return (_jsx("div", { className: this.props.fullScreen ? classes.fullScreen : classes.fullHeight, children: _jsxs("div", { id: this.ROOT_DIV_ID, style: RcsbFv3DComponent.mainDivCssConfig((_a = this.props.cssConfig) === null || _a === void 0 ? void 0 : _a.rootPanel), className: this.useDefaultCss() ? classes.rcsbFvMain : "", onMouseMove: (evt) => { this.mouseMove(evt); }, onMouseUp: (e) => { this.splitPanelMouseUp(); }, children: [_jsx("div", { style: this.structureCssConfig((_b = this.props.cssConfig) === null || _b === void 0 ? void 0 : _b.structurePanel), children: _jsx(RcsbFvStructure, Object.assign({}, this.state.structurePanelConfig, { componentId: this.props.id, structureViewer: this.props.structureViewer, stateManager: this.stateManager, structureViewerBehaviourObserver: this.props.structureViewerBehaviourObserver })) }), _jsx("div", { style: this.sequenceCssConfig((_c = this.props.cssConfig) === null || _c === void 0 ? void 0 : _c.sequencePanel), children: _jsx(RcsbFvSequence, { config: this.state.sequencePanelConfig.config, componentId: this.props.id, stateManager: this.stateManager, title: this.state.sequencePanelConfig.title, subtitle: this.state.sequencePanelConfig.subtitle, unmount: this.props.unmount, rcsbViewBehaviour: this.props.sequencePanelConfig.rcsbViewBehaviour }) }), this.panelDelimiter()] }) }));
    }
    componentDidMount() {
        this.subscription = this.subscribe();
        this.props.resolve();
    }
    componentWillUnmount() {
        this.unsubscribe();
    }
    useDefaultCss() {
        var _a;
        return !((_a = this.props.cssConfig) === null || _a === void 0 ? void 0 : _a.overwriteCss);
    }
    panelDelimiter() {
        return this.useDefaultCss() ? _jsx("div", { onMouseDown: () => {
                this.splitPanelMouseDown();
            }, className: classes.rcsbFvSplitPanel, style: { right: Math.round((1 - this.state.pfvScreenFraction) * 100) + "%" } }) : _jsx(_Fragment, {});
    }
    structureCssConfig(css) {
        const widthFr = Math.round((1 - this.state.pfvScreenFraction) * 100);
        const cssWidth = widthFr.toString() + "%";
        const cssHeight = "100%";
        return Object.assign(Object.assign({}, (this.useDefaultCss() ? { width: cssWidth, height: cssHeight } : {})), css);
    }
    sequenceCssConfig(css) {
        const widthFr = Math.round((this.state.pfvScreenFraction) * 100);
        const cssWidth = widthFr.toString() + "%";
        const cssHeight = "100%";
        return Object.assign(Object.assign({}, (this.useDefaultCss() ? { width: cssWidth, height: cssHeight, overflowY: "auto", overflowX: "hidden", paddingBottom: 5 } : {})), css);
    }
    static mainDivCssConfig(css) {
        return Object.assign({}, css);
    }
    subscribe() {
        return this.props.ctxManager.subscribe((obj) => {
            if (obj.eventType == EventType.UPDATE_CONFIG) {
                this.updateConfig(obj.eventData);
            }
            else if (obj.eventType == EventType.PLUGIN_CALL) {
                this.props.structureViewer.pluginCall(obj.eventData);
            }
        });
    }
    /**Unsubscribe className to rxjs events. Useful if many panels are created an destroyed.*/
    unsubscribe() {
        console.warn('Unsubscribing all observers');
        this.subscription.unsubscribe();
        this.stateManager.unsubscribe();
    }
    updateConfig(config) {
        const structureConfig = config.structurePanelConfig;
        const sequenceConfig = config.sequencePanelConfig;
        if (structureConfig != null && sequenceConfig != null) {
            this.setState({ structurePanelConfig: Object.assign(Object.assign({}, this.state.structurePanelConfig), structureConfig), sequencePanelConfig: Object.assign(Object.assign({}, this.state.sequencePanelConfig), sequenceConfig) });
        }
        else if (structureConfig != null) {
            this.setState({ structurePanelConfig: Object.assign(Object.assign({}, this.state.structurePanelConfig), structureConfig) });
        }
        else if (sequenceConfig != null) {
            this.setState({ sequencePanelConfig: Object.assign(Object.assign({}, this.state.sequencePanelConfig), sequenceConfig) });
        }
    }
    splitPanelMouseDown() {
        const element = document.getElementById(this.ROOT_DIV_ID);
        if (!element)
            return;
        element.style.cursor = "ew-resize";
        document.body.classList.add(classes.disableTextSelection);
        this.resize = (evt) => {
            const rect = element.getBoundingClientRect();
            const x = evt.clientX - rect.left;
            this.setState({ pfvScreenFraction: x / rect.width });
        };
    }
    splitPanelMouseUp() {
        if (typeof this.resize === "function") {
            const element = document.getElementById(this.ROOT_DIV_ID);
            if (!element)
                return;
            element.style.cursor = "auto";
            document.body.classList.remove(classes.disableTextSelection);
            window.dispatchEvent(new Event('resize'));
            this.resize = null;
        }
    }
    mouseMove(evt) {
        if (typeof this.resize === "function")
            this.resize(evt);
    }
}
