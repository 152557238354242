import { __awaiter } from "tslib";
import { DataContainer } from "../../../Utils/DataContainer";
export class AbstractCallbackManager {
    constructor(config) {
        this.isInnerSelection = new DataContainer();
        this.rcsbFvContainer = config.rcsbFvContainer;
        this.stateManager = config.stateManager;
        this.pfvFactory = config.pfvFactory;
    }
    structureViewerSelectionCallback(mode) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.rcsbFvContainer.get() == null)
                return;
            this.isInnerSelection.set(true);
            yield this.innerStructureViewerSelectionChange(mode);
            this.isInnerSelection.set(false);
        });
    }
    pfvSelectionChangeCallback(selection) {
        if (this.isInnerSelection.get())
            return;
        this.innerPfvSelectionChange(selection);
    }
}
