import { __awaiter } from "tslib";
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { unmount } from "@rcsb/rcsb-saguaro-app/lib/RcsbFvWeb/RcsbFvBuilder";
import { AbstractView } from "../AbstractView";
import { DataContainer } from "../../../Utils/DataContainer";
export class RcsbView extends AbstractView {
    constructor(props) {
        var _a;
        super(props);
        this.boardConfigContainer = new DataContainer();
        this.rcsbFvContainer = new DataContainer();
        this.pfvFactory = this.props.pfvManagerFactory.getPfvManager(Object.assign(Object.assign({}, this.props.pfvParams), { rcsbFvContainer: this.rcsbFvContainer, stateManager: this.props.stateManager, boardConfigContainer: this.boardConfigContainer, rcsbFvDivId: this.rcsbFvDivId, pfvChangeCallback: this.pfvChangeCallback.bind(this), additionalConfig: this.props.additionalConfig, useOperatorsFlag: this.props.useOperatorsFlag }));
        this.callbackManager = this.props.callbackManagerFactory.getCallbackManager({
            rcsbFvContainer: this.rcsbFvContainer,
            stateManager: this.props.stateManager,
            pfvFactory: this.pfvFactory
        });
        (_a = this.props.rcsbViewBehaviour) === null || _a === void 0 ? void 0 : _a.observe(this.rcsbFvContainer, this.props.stateManager);
    }
    additionalContent() {
        return this.props.additionalContent ? this.props.additionalContent(this.props) : _jsx(_Fragment, {});
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            const trackWidth = this.getwidth();
            this.boardConfigContainer.set(Object.assign(Object.assign({ trackWidth: trackWidth, highlightHoverPosition: true, highlightHoverElement: true }, (_a = this.props.additionalConfig) === null || _a === void 0 ? void 0 : _a.boardConfig), { elementClickCallback: (e) => {
                    var _a, _b, _c;
                    this.elementClickCallback(e);
                    if (typeof ((_b = (_a = this.props.additionalConfig) === null || _a === void 0 ? void 0 : _a.boardConfig) === null || _b === void 0 ? void 0 : _b.elementClickCallback) === "function")
                        (_c = this.props.additionalConfig) === null || _c === void 0 ? void 0 : _c.boardConfig.elementClickCallback(e);
                }, selectionChangeCallback: (selection) => {
                    var _a, _b, _c;
                    this.selectionChangeCallback(selection);
                    if (typeof ((_b = (_a = this.props.additionalConfig) === null || _a === void 0 ? void 0 : _a.boardConfig) === null || _b === void 0 ? void 0 : _b.selectionChangeCallback) === "function")
                        (_c = this.props.additionalConfig) === null || _c === void 0 ? void 0 : _c.boardConfig.selectionChangeCallback(selection);
                }, highlightHoverCallback: (selection) => {
                    var _a, _b, _c;
                    this.highlightHoverCallback(selection);
                    if (typeof ((_b = (_a = this.props.additionalConfig) === null || _a === void 0 ? void 0 : _a.boardConfig) === null || _b === void 0 ? void 0 : _b.highlightHoverCallback) === "function")
                        (_c = this.props.additionalConfig) === null || _c === void 0 ? void 0 : _c.boardConfig.highlightHoverCallback(selection);
                } }));
            if (this.props.buildPfvOnMount)
                this.rcsbFvContainer.set(yield this.pfvFactory.create());
        });
    }
    componentWillUnmount() {
        var _a;
        super.componentWillUnmount();
        unmount(this.rcsbFvDivId);
        (_a = this.props.rcsbViewBehaviour) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    structureSelectionCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.pluginSelectCallback('select');
        });
    }
    structureHoverCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.pluginSelectCallback('hover');
        });
    }
    representationChangeCallback() {
        //TODO
    }
    updateDimensions() {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            const trackWidth = this.getwidth();
            this.boardConfigContainer.set(Object.assign(Object.assign({}, this.boardConfigContainer.get()), { trackWidth }));
            const select = (_a = this.rcsbFvContainer.get()) === null || _a === void 0 ? void 0 : _a.getFv().getSelection("select");
            const dom = (_b = this.rcsbFvContainer.get()) === null || _b === void 0 ? void 0 : _b.getFv().getDomain();
            yield ((_c = this.rcsbFvContainer.get()) === null || _c === void 0 ? void 0 : _c.getFv().updateBoardConfig({ boardConfigData: { trackWidth: trackWidth } }));
            if (select)
                (_d = this.rcsbFvContainer.get()) === null || _d === void 0 ? void 0 : _d.getFv().setSelection({
                    elements: select.map(s => ({ begin: s.rcsbFvTrackDataElement.begin, end: s.rcsbFvTrackDataElement.end })),
                    mode: "select"
                });
            if (dom)
                (_e = this.rcsbFvContainer.get()) === null || _e === void 0 ? void 0 : _e.getFv().setDomain(dom);
            return void 0;
        });
    }
    modelChangeCallback(defaultAuthId, defaultOperatorName) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.callbackManager.modelChangeCallback(defaultAuthId, defaultOperatorName);
        });
    }
    pluginSelectCallback(mode) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.callbackManager.structureViewerSelectionCallback(mode);
        });
    }
    pfvChangeCallback(context) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.callbackManager.pfvChangeCallback(context);
        });
    }
    highlightHoverCallback(selection) {
        this.callbackManager.highlightHoverCallback(selection);
    }
    selectionChangeCallback(selection) {
        this.callbackManager.pfvSelectionChangeCallback(selection);
    }
    elementClickCallback(e) {
        this.callbackManager.featureClickCallback(e);
    }
    getwidth() {
        var _a, _b, _c, _d, _e, _f, _g;
        const width = (_b = (_a = window.document.getElementById(this.componentDivId)) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().width) !== null && _b !== void 0 ? _b : 0;
        return width - ((_e = (_d = (_c = this.props.additionalConfig) === null || _c === void 0 ? void 0 : _c.boardConfig) === null || _d === void 0 ? void 0 : _d.rowTitleWidth) !== null && _e !== void 0 ? _e : 190) - (((_g = (_f = this.props.additionalConfig) === null || _f === void 0 ? void 0 : _f.boardConfig) === null || _g === void 0 ? void 0 : _g.disableMenu) ? 10 : 55);
    }
}
