import { Structure, StructureElement, StructureProperties as SP } from "molstar/lib/mol-model/structure";
export class MolstarModelMapManager {
    constructor(viewer, delegateModelIdFromTrajectory) {
        this.modelMap = new Map();
        this.viewer = viewer;
        this.delegateModelIdFromTrajectory = delegateModelIdFromTrajectory;
    }
    add(lC, trajectory) {
        this.map(lC.loadParams, trajectory);
    }
    delete(lC) {
        if (lC.loadParams.id) {
            if (this.modelMap.get(lC.loadParams.id))
                this.modelMap.delete(this.modelMap.get(lC.loadParams.id));
            this.modelMap.delete(lC.loadParams.id);
        }
    }
    getChains() {
        const structureRefList = getStructureOptions(this.viewer.plugin);
        const out = new Map();
        structureRefList.forEach((structureRef, i) => {
            const structure = getStructure(structureRef[0], this.viewer.plugin.state.data);
            let modelEntityId = getModelEntityOptions(structure)[0][0];
            const chains = getChainValues(structure, modelEntityId);
            if (chains.length > 0 && chains[0].modelId)
                out.set(this.getModelId(chains[0].modelId), { entryId: chains[0].entryId, assemblyId: chains[0].assemblyId, chains: chains[1] });
        });
        return out;
    }
    getModelId(id) {
        var _a;
        return (_a = this.modelMap.get(id)) !== null && _a !== void 0 ? _a : id;
    }
    getModelIdFromTrajectory(trajectory) {
        return this.delegateModelIdFromTrajectory(trajectory);
    }
    map(loadParams, trajectory) {
        const modelId = this.getModelIdFromTrajectory(trajectory);
        if (!modelId || !loadParams.id)
            throw new Error("modelId not found");
        if (!this.modelMap.has(modelId)) {
            this.modelMap.set(modelId, loadParams.id);
            this.modelMap.set(loadParams.id, modelId);
        }
    }
}
function getStructureOptions(plugin) {
    const options = [];
    plugin.managers.structure.hierarchy.current.structures.forEach(s => {
        options.push([s.cell.transform.ref, s.cell.obj.data.label]);
    });
    return options;
}
function getChainValues(structure, modelEntityId) {
    var _a, _b, _c, _d;
    const chains = new Map();
    const l = StructureElement.Location.create(structure);
    let assemblyId = "-";
    const modelIdx = splitModelEntityId(modelEntityId);
    for (const unit of structure.units) {
        StructureElement.Location.set(l, structure, unit, unit.elements[0]);
        assemblyId = SP.unit.pdbx_struct_assembly_id(l);
        if (structure.getModelIndex(unit.model) !== modelIdx)
            continue;
        const chId = unit.chainGroupId;
        if (chains.has(chId)) {
            chains.get(chId).operators.push(opKey(l));
        }
        else {
            chains.set(chId, { label: SP.chain.label_asym_id(l), auth: SP.chain.auth_asym_id(l), entityId: SP.entity.id(l), title: SP.entity.pdbx_description(l).join("|"), type: SP.entity.type(l), operators: [opKey(l)] });
        }
    }
    const id = { modelId: (_b = (_a = l.unit) === null || _a === void 0 ? void 0 : _a.model) === null || _b === void 0 ? void 0 : _b.id, entryId: (_d = (_c = l.unit) === null || _c === void 0 ? void 0 : _c.model) === null || _d === void 0 ? void 0 : _d.entryId, assemblyId: assemblyId };
    return [id, Array.from(chains.values())];
}
function getStructure(ref, state) {
    const cell = state.select(ref)[0];
    if (!ref || !cell || !cell.obj)
        return Structure.Empty;
    return cell.obj.data;
}
function getModelEntityOptions(structure) {
    const options = [];
    const l = StructureElement.Location.create(structure);
    const seen = new Set();
    for (const unit of structure.units) {
        StructureElement.Location.set(l, structure, unit, unit.elements[0]);
        const id = SP.entity.id(l);
        const modelIdx = structure.getModelIndex(unit.model);
        const key = `${modelIdx}|${id}`;
        if (seen.has(key))
            continue;
        let description = SP.entity.pdbx_description(l).join(', ');
        if (structure.models.length) {
            if (structure.representativeModel) { // indicates model trajectory
                description += ` (Model ${structure.models[modelIdx].modelNum})`;
            }
            else if (description.startsWith('Polymer ')) { // indicates generic entity name
                description += ` (${structure.models[modelIdx].entry})`;
            }
        }
        const label = `${id}: ${description}`;
        options.push([key, label]);
        seen.add(key);
    }
    if (options.length === 0)
        options.push(['', 'No entities']);
    return options;
}
function splitModelEntityId(modelEntityId) {
    return parseInt(modelEntityId.split('|')[0]);
}
function opKey(l) {
    const ids = SP.unit.pdbx_struct_oper_list_ids(l);
    const name = SP.unit.operator_name(l);
    return { ids: ids, name: name };
}
