import { __awaiter } from "tslib";
import { ParamDefinition as PD } from 'molstar/lib/mol-util/param-definition';
import { TrajectoryHierarchyPresetProvider } from "molstar/lib/mol-plugin-state/builder/structure/hierarchy-preset";
import { AssemblyRepresentationPresetProvider } from "./AssemblyRepresentationPresetProvider";
import { StructureElement, StructureProperties as SP } from "molstar/lib/mol-model/structure";
export const AssemblyTrajectoryPresetProvider = TrajectoryHierarchyPresetProvider({
    id: "rcsb-saguaro-3d",
    display: {
        name: 'Feature View 3D'
    },
    params: (trajectory, plugin) => ({
        assemblyId: PD.Value("1"),
        asymId: PD.Value(undefined),
        modelIndex: PD.Value(0)
    }),
    apply(trajectory, params, plugin) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const builder = plugin.builders.structure;
            const model = yield builder.createModel(trajectory, { modelIndex: params.modelIndex });
            const modelProperties = yield builder.insertModelProperties(model);
            let assemblyId = params.assemblyId;
            let structure = yield builder.createStructure(modelProperties, (assemblyId != "" && assemblyId != "0") ? { name: 'assembly', params: { id: assemblyId } } : { name: "model", params: {} });
            if (params.asymId) {
                let asymCheck = false;
                let assemblyId = 1;
                do {
                    plugin.managers.structure.hierarchy.remove([
                        plugin.managers.structure.hierarchy.current.structures[plugin.managers.structure.hierarchy.current.structures.length - 1]
                    ]);
                    structure = yield builder.createStructure(modelProperties, { name: 'assembly', params: { id: (assemblyId++).toString() } });
                    const cell = structure.cell;
                    if (cell) {
                        const units = (_b = (_a = structure.cell) === null || _a === void 0 ? void 0 : _a.obj) === null || _b === void 0 ? void 0 : _b.data.units;
                        const strData = cell.obj.data;
                        if (units) {
                            const l = StructureElement.Location.create(strData);
                            for (const unit of units) {
                                StructureElement.Location.set(l, strData, unit, unit.elements[0]);
                                asymCheck = (SP.chain.label_asym_id(l) == params.asymId);
                                if (asymCheck)
                                    break;
                            }
                        }
                    }
                } while (!asymCheck);
            }
            const structureProperties = yield builder.insertStructureProperties(structure);
            const unitcell = yield builder.tryCreateUnitcell(modelProperties, undefined, { isHidden: true });
            const representation = yield plugin.builders.structure.representation.applyPreset(structureProperties, AssemblyRepresentationPresetProvider);
            return {
                model,
                modelProperties,
                unitcell,
                structure,
                structureProperties,
                representation
            };
        });
    }
});
