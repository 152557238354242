import { __awaiter } from "tslib";
import { StructureRepresentationPresetProvider } from "molstar/lib/mol-plugin-state/builder/structure/representation-preset";
import { StateObjectRef } from "molstar/lib/mol-state";
import { StructureElement, StructureProperties as SP } from "molstar/lib/mol-model/structure";
import { MolScriptBuilder as MS } from "molstar/lib/mol-script/language/builder";
import uniqid from "uniqid";
import { PLDDTConfidenceColorThemeProvider } from "molstar/lib/extensions/model-archive/quality-assessment/color/plddt";
var reprBuilder = StructureRepresentationPresetProvider.reprBuilder;
import { createSelectionExpressions } from "@rcsb/rcsb-molstar/build/src/viewer/helpers/selection";
import { StateTransform } from "molstar/lib/mol-state/transform";
export const AssemblyRepresentationPresetProvider = StructureRepresentationPresetProvider({
    id: "rcsb-saguaro-3d",
    display: {
        name: 'Feature View 3D'
    },
    params(a, plugin) {
        return {};
    },
    apply(structureRef, params, plugin) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const structureCell = StateObjectRef.resolveAndCheck(plugin.state.data, structureRef);
            if (!structureCell)
                return {};
            const structure = structureCell.obj.data;
            const l = StructureElement.Location.create(structure);
            const componentMap = {};
            const representationMap = {};
            const chains = new Set();
            for (const unit of structure.units) {
                StructureElement.Location.set(l, structure, unit, unit.elements[0]);
                const asymId = SP.chain.label_asym_id(l);
                if (chains.has(asymId))
                    continue;
                if (SP.entity.type(l) === "polymer") {
                    chains.add(asymId);
                    const authId = SP.chain.auth_asym_id(l);
                    const comp = yield plugin.builders.structure.tryCreateComponentFromExpression(structureCell, MS.struct.generator.atomGroups({
                        'chain-test': MS.core.logic.and([
                            MS.core.rel.eq([MS.ammp('label_asym_id'), asymId])
                        ])
                    }), uniqid(`${asymId}`), {
                        label: asymId == authId ? asymId : `${asymId} [auth ${authId}]`
                    });
                    componentMap[asymId] = comp;
                    //TODO This needs to be called after tryCreateComponentFromExpression
                    const { update, builder } = reprBuilder(plugin, {
                        ignoreHydrogens: true,
                        ignoreLight: false,
                        quality: "auto"
                    });
                    representationMap[asymId] = builder.buildRepresentation(update, comp, {
                        color: PLDDTConfidenceColorThemeProvider.isApplicable({ structure }) ? PLDDTConfidenceColorThemeProvider.name : "chain-id",
                        type: "cartoon"
                    });
                    yield update.commit({ revertOnError: false });
                }
            }
            for (const expression of createSelectionExpressions("none")) {
                if (expression.tag == "polymer")
                    continue;
                const comp = yield plugin.builders.structure.tryCreateComponentFromExpression(structureCell, expression.expression, uniqid(`${expression.tag}`), {
                    label: `${expression.tag}`
                });
                componentMap[expression.tag] = comp;
                //TODO This needs to be called after tryCreateComponentFromExpression
                const { update, builder } = reprBuilder(plugin, {
                    ignoreHydrogens: true,
                    ignoreLight: false,
                    quality: "auto"
                });
                representationMap[expression.tag] = builder.buildRepresentation(update, comp, {
                    type: expression.type
                }, {
                    initialState: {
                        isHidden: expression.tag == "water"
                    }
                });
                if (expression.type !== "ball-and-stick")
                    representationMap[expression.tag + "#ball-and-stick"] = builder.buildRepresentation(update, comp, {
                        type: "ball-and-stick"
                    }, {
                        initialState: {
                            isHidden: expression.tag == "water"
                        }
                    });
                if (((_a = comp === null || comp === void 0 ? void 0 : comp.cell) === null || _a === void 0 ? void 0 : _a.state) && expression.tag == "water") {
                    StateTransform.assignState((_b = comp === null || comp === void 0 ? void 0 : comp.cell) === null || _b === void 0 ? void 0 : _b.state, { isHidden: true });
                }
                yield update.commit({ revertOnError: false });
            }
            return {
                components: componentMap,
                representations: representationMap
            };
        });
    }
});
