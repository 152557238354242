import { __awaiter } from "tslib";
import { StructureElement, StructureSelection } from "molstar/lib/mol-model/structure";
import { MolScriptBuilder as MS } from "molstar/lib/mol-script/language/builder";
import { Script } from "molstar/lib/mol-script/script";
import { SetUtils } from "molstar/lib/mol-util/set";
export var LoadMethod;
(function (LoadMethod) {
    LoadMethod["loadPdbId"] = "loadPdbId";
    LoadMethod["loadStructureFromUrl"] = "loadStructureFromUrl";
    LoadMethod["loadSnapshotFromUrl"] = "loadSnapshotFromUrl";
    LoadMethod["loadStructureFromData"] = "loadStructureFromData";
})(LoadMethod || (LoadMethod = {}));
export class MolstarActionManager {
    constructor(config) {
        this.viewer = config.viewer;
        this.modelMapManager = config.modelMapManager;
        this.innerSelectionFlag = config.innerSelectionFlag;
        this.innerReprChangeFlag = config.innerReprChangeFlag;
        this.loadingFlag = config.loadingFlag;
    }
    load(loadConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loadingFlag.set(true);
            const out = [];
            for (const lC of (Array.isArray(loadConfig) ? loadConfig : [loadConfig])) {
                if (checkLoadData(lC)) {
                    if (lC.loadMethod == LoadMethod.loadPdbId) {
                        const config = lC.loadParams;
                        out.push(yield this.viewer.loadPdbId(config.entryId, { props: config.props, matrix: config.matrix, reprProvider: config.reprProvider, params: config.params }));
                    }
                    else if (lC.loadMethod == LoadMethod.loadStructureFromUrl) {
                        const config = lC.loadParams;
                        out.push(yield this.viewer.loadStructureFromUrl(config.url, config.format, config.isBinary, { props: config.props, matrix: config.matrix, reprProvider: config.reprProvider, params: config.params }));
                    }
                    else if (lC.loadMethod == LoadMethod.loadSnapshotFromUrl) {
                        const config = lC.loadParams;
                        yield this.viewer.loadSnapshotFromUrl(config.url, config.type);
                    }
                    else if (lC.loadMethod == LoadMethod.loadStructureFromData) {
                        const config = lC.loadParams;
                        out.push(yield this.viewer.loadStructureFromData(config.data, config.format, config.isBinary, { props: config.props, matrix: config.matrix, reprProvider: config.reprProvider, params: config.params }));
                    }
                    const trajectory = out[out.length - 1];
                    if (trajectory)
                        this.modelMapManager.add(lC, trajectory);
                }
            }
            this.loadingFlag.set(false);
            return out.length == 1 ? out[0] : out;
        });
    }
    removeStructure(loadConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            loadConfig = Array.isArray(loadConfig) ? loadConfig : [loadConfig];
            loadConfig.forEach(lC => {
                (Array.isArray(lC.loadParams) ? lC.loadParams : [lC.loadParams]).forEach(loadParams => {
                    if (typeof loadParams.id === "string") {
                        const pdbStr = this.viewer.plugin.managers.structure.hierarchy.current.structures.find(s => { var _a, _b, _c, _d, _e, _f; return ((_f = (_e = (_d = (_c = (_b = (_a = s.properties) === null || _a === void 0 ? void 0 : _a.cell) === null || _b === void 0 ? void 0 : _b.obj) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.units[0]) === null || _e === void 0 ? void 0 : _e.model) === null || _f === void 0 ? void 0 : _f.id) == this.modelMapManager.getModelId(loadParams.id); });
                        if (pdbStr) {
                            this.viewer.plugin.managers.structure.hierarchy.remove([pdbStr]);
                        }
                    }
                });
            });
        });
    }
    select(...args) {
        if (args[5] != undefined) {
            this.selectRange(args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
        }
        else if (Array.isArray(args[0]) && args[0].length > 0 && typeof args[0][0].position === 'number') {
            this.selectSet(args[0], args[1], args[2]);
        }
        else if (Array.isArray(args[0]) && args[0].length > 0 && typeof args[0][0].begin === 'number') {
            this.selectMultipleRanges(args[0], args[1], args[2]);
        }
    }
    selectRange(modelId, labelAsymId, begin, end, mode, operation, operatorName) {
        if (mode == null || mode === 'select') {
            this.innerSelectionFlag.set(true);
        }
        this.viewer.select({ modelId: this.modelMapManager.getModelId(modelId), labelAsymId: labelAsymId, labelSeqRange: { beg: begin, end: end }, operatorName: operatorName }, mode, operation);
        this.innerSelectionFlag.set(false);
    }
    selectSet(selection, mode, operation) {
        if (mode == null || mode === 'select') {
            this.innerSelectionFlag.set(true);
        }
        this.viewer.select(selection.map(r => ({ modelId: this.modelMapManager.getModelId(r.modelId), labelSeqId: r.position, labelAsymId: r.labelAsymId, operatorName: r.operatorName })), mode, operation);
        this.innerSelectionFlag.set(false);
    }
    selectMultipleRanges(selection, mode, operation) {
        if (mode == null || mode === 'select') {
            this.innerSelectionFlag.set(true);
        }
        this.viewer.select(selection.map(r => ({ modelId: this.modelMapManager.getModelId(r.modelId), labelAsymId: r.labelAsymId, labelSeqRange: { beg: r.begin, end: r.end }, operatorName: r.operatorName })), mode, operation);
        this.innerSelectionFlag.set(false);
    }
    clear() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.viewer.clear();
        });
    }
    clearSelection(mode, option) {
        if (mode === 'select') {
            this.viewer.clearFocus();
            this.innerSelectionFlag.set(true);
        }
        if (option != null)
            this.viewer.clearSelection(mode, Object.assign(Object.assign({}, option), { modelId: this.modelMapManager.getModelId(option.modelId) }));
        else
            this.viewer.clearSelection(mode);
        this.innerSelectionFlag.set(false);
    }
    setFocus(modelId, labelAsymId, begin, end, operatorName) {
        this.viewer.setFocus({ modelId: this.modelMapManager.getModelId(modelId), labelAsymId: labelAsymId, labelSeqRange: { beg: begin, end: end }, operatorName: operatorName });
    }
    clearFocus() {
        this.viewer.clearFocus();
    }
    cameraFocus(...args) {
        if (Array.isArray(args[2])) {
            this.focusPositions(args[0], args[1], args[2], args[3]);
        }
        else {
            this.focusRange(args[0], args[1], args[2], args[3], args[4]);
        }
    }
    focusRange(modelId, labelAsymId, begin, end, operatorName) {
        const seqIds = new Array();
        for (let n = begin; n <= end; n++) {
            seqIds.push(n);
        }
        this.focusPositions(modelId, labelAsymId, seqIds, operatorName);
    }
    focusPositions(modelId, labelAsymId, positions, operatorName) {
        const structure = getStructureWithModelId(this.viewer.plugin.managers.structure.hierarchy.current.structures, this.modelMapManager.getModelId(modelId));
        if (structure == null)
            return;
        const chainTests = [MS.core.rel.eq([MS.ammp('label_asym_id'), labelAsymId])];
        if (operatorName)
            chainTests.push(MS.core.rel.eq([operatorName, MS.acp('operatorName')]));
        const sel = Script.getStructureSelection(Q => Q.struct.generator.atomGroups({
            'chain-test': Q.core.logic.and(chainTests),
            'residue-test': Q.core.set.has([MS.set(...SetUtils.toArray(new Set(positions))), MS.ammp('label_seq_id')])
        }), structure);
        const loci = StructureSelection.toLociWithSourceUnits(sel);
        if (!StructureElement.Loci.isEmpty(loci)) {
            this.viewer.plugin.managers.camera.focusLoci(loci);
        }
        else {
            this.resetCamera();
        }
    }
    createComponent(...args) {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerReprChangeFlag.set(true);
            yield this.removeComponent(args[0]);
            if (Array.isArray(args[1])) {
                if (args[1].length > 0) {
                    if (typeof args[1][0].position === "number") {
                        yield this.viewer.createComponent(args[0], args[1].map(r => ({ modelId: this.modelMapManager.getModelId(r.modelId), labelAsymId: r.labelAsymId, labelSeqId: r.position, operatorName: r.operatorName })), args[2]);
                    }
                    else {
                        yield this.viewer.createComponent(args[0], args[1].map(r => ({ modelId: this.modelMapManager.getModelId(r.modelId), labelAsymId: r.labelAsymId, labelSeqRange: { beg: r.begin, end: r.end }, operatorName: r.operatorName })), args[2]);
                    }
                }
            }
            else if (args[5] != undefined) {
                yield this.viewer.createComponent(args[0], { modelId: this.modelMapManager.getModelId(args[1]), labelAsymId: args[2], labelSeqRange: { beg: args[3], end: args[4] }, operatorName: args[6] }, args[5]);
            }
            else {
                yield this.viewer.createComponent(args[0], { modelId: this.modelMapManager.getModelId(args[1]), labelAsymId: args[2], operatorName: args[4] }, args[3]);
            }
            this.innerReprChangeFlag.set(false);
        });
    }
    isComponent(componentLabel) {
        var _a;
        for (const c of this.viewer.plugin.managers.structure.hierarchy.currentComponentGroups) {
            for (const comp of c) {
                if (((_a = comp.cell.obj) === null || _a === void 0 ? void 0 : _a.label) === componentLabel) {
                    return true;
                }
            }
        }
        return false;
    }
    colorComponent(componentLabel, color) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            for (const c of this.viewer.plugin.managers.structure.hierarchy.currentComponentGroups) {
                for (const comp of c) {
                    if (((_a = comp.cell.obj) === null || _a === void 0 ? void 0 : _a.label) === componentLabel) {
                        yield this.viewer.plugin.managers.structure.component.updateRepresentationsTheme([comp], { color: color });
                        return;
                    }
                }
            }
        });
    }
    getComponentSet() {
        const out = new Set();
        this.viewer.plugin.managers.structure.hierarchy.currentComponentGroups.forEach((c) => {
            var _a, _b, _c, _d;
            for (const comp of c) {
                if (((_a = comp.cell.obj) === null || _a === void 0 ? void 0 : _a.label) != null && out.has((_b = comp.cell.obj) === null || _b === void 0 ? void 0 : _b.label)) {
                    break;
                }
                else if (((_c = comp.cell.obj) === null || _c === void 0 ? void 0 : _c.label) != null) {
                    out.add((_d = comp.cell.obj) === null || _d === void 0 ? void 0 : _d.label);
                }
            }
        });
        return out;
    }
    removeComponent(componentLabel) {
        return __awaiter(this, void 0, void 0, function* () {
            if (componentLabel == null) {
                this.viewer.plugin.managers.structure.hierarchy.currentComponentGroups.forEach(c => this.viewer.plugin.managers.structure.hierarchy.remove(c));
            }
            else {
                yield this.viewer.removeComponent(componentLabel);
            }
        });
    }
    displayComponent(componentLabel, visibilityFlag) {
        if (typeof visibilityFlag === 'boolean')
            return this.changeComponentDisplay(componentLabel, visibilityFlag);
        else
            return this.getComponentDisplay(componentLabel);
    }
    changeComponentDisplay(componentLabel, visibilityFlag) {
        var _a;
        this.innerReprChangeFlag.set(true);
        for (const c of this.viewer.plugin.managers.structure.hierarchy.currentComponentGroups) {
            for (const comp of c) {
                if (((_a = comp.cell.obj) === null || _a === void 0 ? void 0 : _a.label) === componentLabel) {
                    if (!comp.cell.state.isHidden != visibilityFlag) {
                        this.viewer.plugin.managers.structure.component.toggleVisibility(c);
                        return void 0;
                    }
                }
            }
        }
        this.innerReprChangeFlag.set(false);
    }
    getComponentDisplay(componentLabel) {
        var _a;
        for (const c of this.viewer.plugin.managers.structure.hierarchy.currentComponentGroups) {
            for (const comp of c) {
                if (((_a = comp.cell.obj) === null || _a === void 0 ? void 0 : _a.label) === componentLabel) {
                    return !comp.cell.state.isHidden;
                }
            }
        }
        return false;
    }
    resetCamera() {
        this.viewer.plugin.managers.camera.reset();
    }
    exportLoadedStructures() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.viewer.exportLoadedStructures();
        });
    }
}
function getStructureWithModelId(structures, modelId) {
    var _a, _b, _c;
    for (const structure of structures) {
        if (!((_c = (_b = (_a = structure.cell) === null || _a === void 0 ? void 0 : _a.obj) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.units))
            continue;
        const unit = structure.cell.obj.data.units[0];
        const id = unit.model.id;
        if (id === modelId)
            return structure.cell.obj.data;
    }
}
function checkLoadData(loadConfig) {
    const method = loadConfig.loadMethod;
    const params = loadConfig.loadParams;
    if (method == LoadMethod.loadPdbId) {
        if (params instanceof Array || params.entryId == null)
            throw loadConfig.loadMethod + ": missing pdbId";
    }
    else if (method == LoadMethod.loadStructureFromUrl) {
        if (params instanceof Array || params.url == null || params.isBinary == null || params.format == null)
            throw loadConfig.loadMethod + ": arguments needed url, format, isBinary";
    }
    else if (method == LoadMethod.loadSnapshotFromUrl) {
        if (params instanceof Array || params.url == null || params.type == null)
            throw loadConfig.loadMethod + ": arguments needed url, type";
    }
    else if (method == LoadMethod.loadStructureFromData) {
        if (params instanceof Array || params.data == null || params.format == null || params.isBinary == null)
            throw loadConfig.loadMethod + ": arguments needed data, format, isBinary";
    }
    return true;
}
