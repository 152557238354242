export class DataContainer {
    constructor(data) {
        this.data = undefined;
        this.data = data;
    }
    get() {
        return this.data;
    }
    set(data) {
        this.data = data;
    }
}
