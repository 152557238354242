import { jsx as _jsx } from "react/jsx-runtime";
import { RcsbFv3DAbstract } from "./RcsbFv3DAbstract";
import { StructureViewer } from "../RcsbFvStructure/StructureViewers/StructureViewer";
import { MolstarManagerFactory } from "../RcsbFvStructure/StructureViewers/MolstarViewer/MolstarManagerFactory";
import uniqid from "uniqid";
import { RcsbFvStructure } from "../RcsbFvStructure/RcsbFvStructure";
import { AssemblyPfvManagerFactory } from "../RcsbFvSequence/SequenceViews/RcsbView/PfvManagerFactoryImplementation/AssemblyPfvManagerFactory";
import { AssemblyCallbackManagerFactory } from "../RcsbFvSequence/SequenceViews/RcsbView/CallbackManagerFactoryImplementation/AssemblyCallbackManager";
import { AssemblyBehaviourObserver } from "../RcsbFvStructure/StructureViewerBehaviour/AssemblyBehaviour";
import { HelpLinkComponent } from "../RcsbFvSequence/SequenceViews/RcsbView/Components/HelpLinkComponent";
import { MolstarTools } from "../RcsbFvStructure/StructureViewers/MolstarViewer/MolstarUtils/MolstarTools";
var getModelIdFromTrajectory = MolstarTools.getModelIdFromTrajectory;
import { MolstarAssemblyLoader } from "../RcsbFvStructure/StructureViewers/MolstarViewer/MolstarUtils/MolstarAssemblyLoader";
export class RcsbFv3DAssembly extends RcsbFv3DAbstract {
    constructor(params) {
        var _a, _b, _c, _d;
        const elementId = (_a = params.elementId) !== null && _a !== void 0 ? _a : uniqid("RcsbFv3D_");
        super({
            elementId: (_b = params.elementId) !== null && _b !== void 0 ? _b : elementId,
            sequenceConfig: {
                title: params.config.title,
                subtitle: params.config.subtitle,
                config: {
                    rcsbId: params.config.entryId,
                    additionalConfig: params.additionalConfig,
                    useOperatorsFlag: params.useOperatorsFlag,
                    pfvParams: {
                        instanceSequenceConfig: params.instanceSequenceConfig
                    },
                    pfvManagerFactory: new AssemblyPfvManagerFactory(),
                    callbackManagerFactory: new AssemblyCallbackManagerFactory(),
                    additionalContent: (props) => (_jsx(HelpLinkComponent, Object.assign({}, props, { helpHref: "/docs/sequence-viewers/3d-protein-feature-view" })))
                }
            },
            structureConfig: {
                structureViewerConfig: {
                    viewerElement: RcsbFvStructure.componentId(elementId),
                    viewerProps: (_c = params.molstarProps) !== null && _c !== void 0 ? _c : {}
                }
            },
            structureViewer: new StructureViewer(new MolstarManagerFactory(getModelIdFromTrajectory)),
            structureViewerBehaviourObserver: new AssemblyBehaviourObserver(new MolstarAssemblyLoader({
                entryId: params.config.entryId,
                assemblyId: typeof (params.config.assemblyId) === "string" && ((_d = params.config.assemblyId) === null || _d === void 0 ? void 0 : _d.length) > 0 ? params.config.assemblyId : '1',
                asymId: params.config.asymId
            })),
            cssConfig: params.cssConfig
        });
    }
}
