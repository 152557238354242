import { __awaiter } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from "react-dom/client";
import { RcsbFv3DComponent } from './RcsbFv3DComponent';
import { EventType, RcsbFvContextManager } from "../RcsbFvContextManager/RcsbFvContextManager";
export class RcsbFv3DAbstract {
    constructor(config) {
        this.ctxManager = new RcsbFvContextManager();
        this.fullScreenFlag = false;
        this.overflowStyle = "";
        this.elementId = config.elementId;
        if (config.cssConfig)
            this.cssConfig = config.cssConfig;
        this.sequenceConfig = config.sequenceConfig;
        this.structureConfig = config.structureConfig;
        this.structureViewer = config.structureViewer;
        this.structureViewerBehaviourObserver = config.structureViewerBehaviourObserver;
    }
    render() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.elementId == null)
                throw new Error("HTML element not found");
            const element = (_a = document.getElementById(this.elementId)) !== null && _a !== void 0 ? _a : document.createElement("div");
            if (element.getAttribute("id") == null) {
                element.setAttribute("id", this.elementId);
                document.body.append(element);
                this.fullScreen("on");
            }
            this.reactRoot = createRoot(element);
            return new Promise((resolve) => {
                this.reactRoot.render(_jsx(RcsbFv3DComponent, { structurePanelConfig: this.structureConfig, sequencePanelConfig: this.sequenceConfig, id: this.elementId, ctxManager: this.ctxManager, cssConfig: this.cssConfig, unmount: this.unmount.bind(this), fullScreen: this.fullScreenFlag, structureViewer: this.structureViewer, structureViewerBehaviourObserver: this.structureViewerBehaviourObserver, resolve: resolve }));
            });
        });
    }
    unmount(removeHtmlElement, unmountCallback) {
        const element = document.getElementById(this.elementId);
        if (element != null) {
            this.reactRoot.unmount();
            if (removeHtmlElement) {
                element.remove();
            }
            if (typeof unmountCallback === "function")
                unmountCallback();
            this.fullScreen("off");
        }
    }
    updateConfig(config) {
        this.ctxManager.next({ eventType: EventType.UPDATE_CONFIG, eventData: config });
    }
    pluginCall(f) {
        this.structureViewer.pluginCall(f);
    }
    fullScreen(mode) {
        switch (mode) {
            case "on":
                this.fullScreenFlag = true;
                this.overflowStyle = document.body.style.overflow;
                document.body.style.overflow = "hidden";
                break;
            case "off":
                this.fullScreenFlag = false;
                document.body.style.overflow = this.overflowStyle;
                break;
        }
    }
}
