import { __awaiter } from "tslib";
import { AbstractCallbackManager } from "../CallbackManagerFactoryInterface";
export class AssemblyCallbackManagerFactory {
    getCallbackManager(config) {
        return new AssemblyCallbackManager(config);
    }
}
class AssemblyCallbackManager extends AbstractCallbackManager {
    featureClickCallback(e) {
        var _a, _b;
        if (e == null) {
            this.stateManager.selectionState.setLastSelection(null);
        }
        else {
            const x = e.begin;
            const y = (_a = e.end) !== null && _a !== void 0 ? _a : e.begin;
            const modelId = this.stateManager.assemblyModelSate.getString("modelId");
            const labelAsymId = this.stateManager.assemblyModelSate.getString("labelAsymId");
            const operatorName = (_b = this.stateManager.assemblyModelSate.getOperator()) === null || _b === void 0 ? void 0 : _b.name;
            if (e.isEmpty)
                this.stateManager.selectionState.setLastSelection({
                    modelId, labelAsymId, operatorName, source: "sequence", regions: [
                        { begin: x, end: x, source: "sequence" },
                        { begin: y, end: y, source: "sequence" }
                    ]
                });
            else
                this.stateManager.selectionState.setLastSelection({
                    modelId,
                    labelAsymId,
                    operatorName,
                    source: "sequence",
                    regions: processGaps(modelId, labelAsymId, e, operatorName).map(r => (Object.assign(Object.assign({}, r), { source: "sequence" })))
                });
        }
        this.stateManager.next({ type: "feature-click", view: "1d-view" });
    }
    highlightHoverCallback(selection) {
        this.select(selection, "hover", "set");
    }
    modelChangeCallback(defaultAuthId, defaultOperatorName) {
        return __awaiter(this, void 0, void 0, function* () {
            this.rcsbFvContainer.set(yield this.pfvFactory.create({ defaultAuthId, defaultOperatorName }));
        });
    }
    pfvChangeCallback() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            this.stateManager.selectionState.setLastSelection(null);
            (_a = this.rcsbFvContainer.get()) === null || _a === void 0 ? void 0 : _a.getFv().then(() => __awaiter(this, void 0, void 0, function* () {
                this.stateManager.next({ type: "pfv-change", view: "1d-view" });
                yield this.structureViewerSelectionCallback("select");
            }));
        });
    }
    innerStructureViewerSelectionChange(mode) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            const allSel = this.stateManager.selectionState.getSelection(mode);
            const lastSel = this.stateManager.selectionState.getLastSelection();
            const modelId = this.stateManager.assemblyModelSate.getString("modelId");
            const labelAsymId = this.stateManager.assemblyModelSate.getString("labelAsymId");
            const operatorName = (_a = this.stateManager.assemblyModelSate.getOperator()) === null || _a === void 0 ? void 0 : _a.name;
            if (allSel == null || allSel.length === 0) {
                (_b = this.rcsbFvContainer.get()) === null || _b === void 0 ? void 0 : _b.getFv().clearSelection(mode);
            }
            else if (mode === 'select' && (((lastSel === null || lastSel === void 0 ? void 0 : lastSel.labelAsymId) && (lastSel === null || lastSel === void 0 ? void 0 : lastSel.labelAsymId) != labelAsymId) || ((lastSel === null || lastSel === void 0 ? void 0 : lastSel.operatorName) && (lastSel === null || lastSel === void 0 ? void 0 : lastSel.operatorName) != operatorName))) {
                const labelAsymId = (_c = this.stateManager.assemblyModelSate.getChainInfo(lastSel === null || lastSel === void 0 ? void 0 : lastSel.labelAsymId)) === null || _c === void 0 ? void 0 : _c.label;
                yield this.modelChangeCallback(labelAsymId, lastSel === null || lastSel === void 0 ? void 0 : lastSel.operatorName);
            }
            else if (modelId && labelAsymId) {
                const sel = this.stateManager.selectionState.getSelectionWithCondition(modelId, labelAsymId, mode, operatorName);
                if (sel == null) {
                    (_d = this.rcsbFvContainer.get()) === null || _d === void 0 ? void 0 : _d.getFv().clearSelection(mode);
                }
                else {
                    (_e = this.rcsbFvContainer.get()) === null || _e === void 0 ? void 0 : _e.getFv().setSelection({ elements: sel.regions, mode: mode });
                }
            }
        });
    }
    innerPfvSelectionChange(selection) {
        if (selection.length == 0 && this.stateManager.selectionState.getLastSelection() != null)
            return;
        this.select(selection, "select", "set");
    }
    select(selection, mode, operator) {
        var _a;
        const modelId = this.stateManager.assemblyModelSate.getString("modelId");
        const labelAsymId = this.stateManager.assemblyModelSate.getString("labelAsymId");
        const operatorName = (_a = this.stateManager.assemblyModelSate.getOperator()) === null || _a === void 0 ? void 0 : _a.name;
        if (Array.isArray(selection) && selection.length > 0) {
            const regions = [];
            selection.forEach(s => {
                var _a;
                if (s.isEmpty) {
                    regions.push({
                        modelId,
                        labelAsymId,
                        operatorName,
                        region: {
                            begin: s.begin,
                            end: s.begin,
                            source: "sequence"
                        }
                    });
                    regions.push({
                        modelId,
                        labelAsymId,
                        operatorName,
                        region: {
                            begin: s.end,
                            end: s.end,
                            source: "sequence"
                        }
                    });
                }
                else {
                    regions.push({
                        modelId,
                        labelAsymId,
                        operatorName,
                        region: {
                            begin: s.begin,
                            end: (_a = s.end) !== null && _a !== void 0 ? _a : s.begin,
                            source: "sequence"
                        }
                    });
                }
            });
            this.stateManager.selectionState.selectFromMultipleRegions("set", regions, mode);
        }
        else {
            this.stateManager.selectionState.clearSelection(mode, { modelId, labelAsymId, operatorName });
        }
        this.stateManager.next({ type: mode == "select" ? "selection-change" : "hover-change", view: "1d-view" });
    }
}
function processGaps(modelId, labelAsymId, e, operatorName) {
    var _a, _b;
    const regions = new Array();
    let lastIndex = e.begin;
    (_a = e.gaps) === null || _a === void 0 ? void 0 : _a.forEach((g) => {
        regions.push({
            modelId: modelId,
            labelAsymId: labelAsymId,
            begin: lastIndex,
            end: g.begin,
            operatorName: operatorName
        });
        lastIndex = g.end;
    });
    regions.push({
        modelId: modelId,
        labelAsymId: labelAsymId,
        begin: lastIndex,
        end: (_b = e.end) !== null && _b !== void 0 ? _b : e.begin,
        operatorName: operatorName
    });
    return regions;
}
