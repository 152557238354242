import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
export class ChainDisplayComponent extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            display: 'visible'
        };
    }
    render() {
        return (_jsx("input", { style: { marginLeft: 5, marginRight: 5 }, type: 'checkbox', checked: this.state.display === 'visible', onChange: this.changeDisplay.bind(this) }));
    }
    componentDidMount() {
        this.subscribe();
        this.requestInfo();
    }
    componentWillUnmount() {
        this.subscription.unsubscribe();
    }
    subscribe() {
        this.subscription = this.props.stateManager.subscribe((o) => {
            if (o.type == "component-info" && o.view == "3d-view" && o.data)
                this.componentInfo(o.data);
        });
    }
    changeDisplay() {
        const display = this.state.display === "visible" ? "hidden" : "visible";
        this.setState({ display }, () => {
            this.props.stateManager.next({
                data: {
                    display,
                    label: this.props.label
                },
                type: "visibility-change",
                view: "1d-view"
            });
        });
    }
    componentInfo(data) {
        if (data.label === this.props.label)
            this.setState({ display: data.display });
    }
    requestInfo() {
        this.props.stateManager.next({
            type: "component-info",
            view: "1d-view",
            data: {
                label: this.props.label
            }
        });
    }
}
