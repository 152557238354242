import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { RcsbFvDOMConstants } from "../../../../RcsbFvConstants/RcsbFvConstants";
export class HelpLinkComponent extends React.Component {
    render() {
        return (_jsxs("div", { style: { marginTop: 10 }, children: [_jsx("div", { children: _jsx("div", { id: RcsbFvDOMConstants.SELECT_BUTTON_PFV_ID, style: { display: "inline-block" } }) }), _jsxs("div", { style: { position: "absolute", top: 5, right: 5 }, children: [_jsx("a", { style: { textDecoration: "none", color: "#337ab7", cursor: "pointer", marginRight: 15 }, target: "_blank", href: this.props.helpHref, children: "Help" }), _jsx("a", { style: { textDecoration: "none", color: "#337ab7", cursor: "pointer" }, onClick: () => {
                                this.props.unmount(true, () => {
                                    window.history.back();
                                });
                            }, children: "Back" })] })] }));
    }
}
