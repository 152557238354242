import { Bond, StructureElement, StructureProperties, Unit } from 'molstar/lib/mol-model/structure';
import { Color } from 'molstar/lib/mol-util/color';
import { ParamDefinition } from 'molstar/lib/mol-util/param-definition';
import { ColorTheme } from 'molstar/lib/mol-theme/color';
import { OrderedSet } from "molstar/lib/mol-data/int";
import { ElementSymbolColorTheme, ElementSymbolColorThemeParams, getElementSymbolColorThemeParams } from "molstar/lib/mol-theme/color/element-symbol";
export const FOCUS_RESIDUE_COLOR = 'focus-residue-color';
export function FocusResidueColorTheme(ctx, props) {
    const L = StructureElement.Location.create();
    const bondColor = (location) => {
        var _a, _b;
        for (const repr of (_a = ctx.structure) === null || _a === void 0 ? void 0 : _a.inheritedPropertyData.reprList) {
            const layer = repr.obj.data.repr.state.overpaint.layers.find((layer) => {
                if (!StructureElement.Loci.is(layer.loci))
                    return false;
                return isLocationInLoci(location, layer.loci);
            });
            if (layer)
                return layer.color;
        }
        return (_b = ctx.structure) === null || _b === void 0 ? void 0 : _b.inheritedPropertyData.reprList[0].obj.data.repr.theme.color.color(location);
    };
    const nonCarbonAtomsColor = ElementSymbolColorTheme(ctx, props).color;
    const atomColor = (location) => {
        if (location.unit.model.atomicHierarchy.atoms.type_symbol.value(location.element) == 'C')
            return bondColor(location);
        return nonCarbonAtomsColor(location);
    };
    const color = (location) => {
        if (StructureElement.Location.is(location) && Unit.isAtomic(location.unit)) {
            return atomColor(location);
        }
        else if (Bond.isLocation(location)) {
            L.structure = location.aStructure;
            L.unit = location.aUnit;
            L.element = location.aUnit.elements[location.aIndex];
            return bondColor(L);
        }
        return Color(0x777777);
    };
    return {
        factory: FocusResidueColorTheme,
        granularity: 'group',
        color,
        props
    };
}
export const FocusResidueColorThemeProvider = {
    name: FOCUS_RESIDUE_COLOR,
    label: 'Focus Residue',
    category: ColorTheme.Category.Misc,
    factory: FocusResidueColorTheme,
    getParams: getElementSymbolColorThemeParams,
    defaultValues: ParamDefinition.getDefaultValues(ElementSymbolColorThemeParams),
    isApplicable: () => true,
};
function isLocationInLoci(location, loci) {
    const modelId = location.structure.model.id;
    const seqId = StructureProperties.residue.label_seq_id(location);
    const asymId = StructureProperties.chain.label_asym_id(location);
    const currentLoci = loci;
    const loc = StructureElement.Location.create(currentLoci.structure);
    const layerModelId = loc.structure.model.id;
    for (let n = 0; n < OrderedSet.size(currentLoci.elements[0].indices); n++) {
        StructureElement.Location.set(loc, currentLoci.structure, currentLoci.elements[0].unit, currentLoci.elements[0].unit.elements[OrderedSet.getAt(currentLoci.elements[0].indices, n)]);
        const layerSeqId = StructureProperties.residue.label_seq_id(loc);
        const layerAsymId = StructureProperties.chain.label_asym_id(loc);
        if (modelId == layerModelId && asymId == layerAsymId && seqId == layerSeqId)
            return true;
    }
    return false;
}
