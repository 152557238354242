import { __awaiter } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { RcsbFvDOMConstants } from "../RcsbFvConstants/RcsbFvConstants";
export class RcsbFvStructure extends React.Component {
    render() {
        return (_jsx("div", { id: this.props.componentId + "_" + RcsbFvDOMConstants.MOLSTAR_DIV, children: _jsx("div", { id: RcsbFvStructure.componentId(this.props.componentId), style: { position: "absolute" } }) }));
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            this.updateDimensions();
            this.props.structureViewer.init(this.props.stateManager, this.props.structureViewerConfig);
            this.props.structureViewerBehaviourObserver.observe(this.props.structureViewer, this.props.stateManager);
            if (this.props.loadConfig)
                yield this.props.structureViewer.load(this.props.loadConfig);
            window.addEventListener('resize', this.updateDimensions.bind(this));
        });
    }
    componentWillUnmount() {
        this.props.structureViewerBehaviourObserver.unsubscribe();
    }
    updateDimensions() {
        var _a;
        const div = (_a = document.getElementById(this.props.componentId + "_" + RcsbFvDOMConstants.MOLSTAR_DIV)) === null || _a === void 0 ? void 0 : _a.parentElement;
        if (div == null)
            return;
        const rect = div.getBoundingClientRect();
        RcsbFvStructure.setSize(document.getElementById(this.props.componentId + "_" + RcsbFvDOMConstants.MOLSTAR_DIV), rect);
        RcsbFvStructure.setSize(document.getElementById(this.props.componentId + "_" + RcsbFvDOMConstants.MOLSTAR_APP_ID), rect);
    }
    static setSize(element, rect) {
        if (element == null)
            return;
        if (rect == null)
            return;
        element.style.width = rect.width + "px";
        element.style.height = rect.height + "px";
    }
    static componentId(id) {
        return `${id}_${RcsbFvDOMConstants.MOLSTAR_APP_ID}`;
    }
}
