import { __awaiter } from "tslib";
import { TagDelimiter } from "@rcsb/rcsb-api-tools/build/RcsbUtils/TagDelimiter";
export class AlignmentProviderBehaviour {
    constructor() {
        this.subscription = undefined;
    }
    observe(rcsbFvContainer, stateManager) {
        this.subscription = stateManager.subscribe((o) => __awaiter(this, void 0, void 0, function* () {
            if (o.type == "model-ready" && o.data)
                yield loadNextModel(o.data, rcsbFvContainer, stateManager);
        }));
    }
    unsubscribe() {
        var _a;
        (_a = this.subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
}
function loadNextModel(data, rcsbFvContainer, stateManager) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const alignments = yield ((_a = rcsbFvContainer.get()) === null || _a === void 0 ? void 0 : _a.getAlignmentResponse());
        if (!alignments || !alignments.target_alignment)
            return;
        if (data.who == "user")
            return;
        const pdb = data.pdb;
        const targetAlignment = data.targetAlignment;
        const index = alignments.target_alignment.findIndex(ta => (ta === null || ta === void 0 ? void 0 : ta.target_id) == `${pdb.entryId}${TagDelimiter.instance}${pdb.instanceId}`);
        if (typeof index === "undefined" || index < 0 || index == (alignments.target_alignment.length - 1))
            return;
        const targetId = (_b = alignments.target_alignment[index + 1]) === null || _b === void 0 ? void 0 : _b.target_id;
        if (!targetId)
            return;
        stateManager.next({
            type: "model-change",
            view: "1d-view",
            data: {
                pdb: TagDelimiter.parseInstance(targetId),
                targetAlignment,
                who: "auto"
            }
        });
    });
}
