// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msaRowMark{width:0;height:0;border-top:6px solid rgba(0,0,0,0);border-bottom:6px solid rgba(0,0,0,0);border-left:6px solid}`, "",{"version":3,"sources":["webpack://./lib/scss/MsaPfvStyle.module.scss"],"names":[],"mappings":"AACA,YACE,OAAA,CACA,QAAA,CACA,kCAAA,CACA,qCAAA,CACA,qBAAA","sourcesContent":["\n.msaRowMark {\n  width: 0;\n  height: 0;\n  border-top: 6px solid transparent;\n  border-bottom: 6px solid transparent;\n  border-left: 6px solid ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msaRowMark": `msaRowMark`
};
export default ___CSS_LOADER_EXPORT___;
