import { MolstarActionManager } from "./MolstarActionManager";
import { Viewer } from "@rcsb/rcsb-molstar/build/src/viewer";
import { MolstarModelMapManager } from "./MolstarModelMapManager";
import { MolstarCallbackManager } from "./MolstarCallbackManager";
import { DataContainer } from "../../../Utils/DataContainer";
export class MolstarManagerFactory {
    constructor(getModelIdFromTrajectory) {
        this.getModelIdFromTrajectory = getModelIdFromTrajectory;
    }
    getViewerManagerFactory(stateManager, viewerParams) {
        const loadingFlag = new DataContainer(false);
        const innerSelectionFlag = new DataContainer(false);
        const innerReprChangeFlag = new DataContainer(false);
        const viewer = new Viewer(viewerParams.viewerElement, Object.assign(Object.assign({}, viewerParams.viewerProps), { layoutShowControls: false, layoutShowSequence: true, canvas3d: {
                multiSample: {
                    mode: 'off'
                }
            } }));
        viewer.plugin.selectionMode = true;
        const modelMapManager = new MolstarModelMapManager(viewer, this.getModelIdFromTrajectory);
        const callbackManager = new MolstarCallbackManager({
            viewer: viewer,
            stateManager: stateManager,
            loadingFlag: loadingFlag,
            modelMapManager: modelMapManager,
            innerSelectionFlag: innerSelectionFlag,
            innerReprChangeFlag: innerReprChangeFlag
        });
        const actionManager = new MolstarActionManager({
            viewer: viewer,
            modelMapManager: modelMapManager,
            innerSelectionFlag: innerSelectionFlag,
            innerReprChangeFlag: innerReprChangeFlag,
            loadingFlag: loadingFlag
        });
        return {
            actionManager,
            callbackManager
        };
    }
}
