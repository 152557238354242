import { jsx as _jsx } from "react/jsx-runtime";
import { RcsbFv3DAbstract } from "./RcsbFv3DAbstract";
import uniqid from "uniqid";
import { LoadMethod } from "../RcsbFvStructure/StructureViewers/MolstarViewer/MolstarActionManager";
import { StructureViewer } from "../RcsbFvStructure/StructureViewers/StructureViewer";
import { MolstarManagerFactory } from "../RcsbFvStructure/StructureViewers/MolstarViewer/MolstarManagerFactory";
import { MsaCallbackManagerFactory } from "../RcsbFvSequence/SequenceViews/RcsbView/CallbackManagerFactoryImplementation/MsaCallbackManager";
import { RcsbFvStructure } from "../RcsbFvStructure/RcsbFvStructure";
import { MolstarAlignmentLoader } from "../RcsbFvStructure/StructureViewers/MolstarViewer/MolstarUtils/MolstarAlignmentLoader";
import { MsaBehaviourObserver } from "../RcsbFvStructure/StructureViewerBehaviour/MsaBehaviour";
import { HelpLinkComponent } from "../RcsbFvSequence/SequenceViews/RcsbView/Components/HelpLinkComponent";
import { DataContainer } from "../Utils/DataContainer";
import { MsaPfvManagerFactory } from "../RcsbFvSequence/SequenceViews/RcsbView/PfvManagerFactoryImplementation/MsaPfvManagerFactory";
import { MolstarTools } from "../RcsbFvStructure/StructureViewers/MolstarViewer/MolstarUtils/MolstarTools";
var getModelIdFromTrajectory = MolstarTools.getModelIdFromTrajectory;
import { buildSequenceIdentityAlignmentFv } from "@rcsb/rcsb-saguaro-app/lib/RcsbFvWeb/RcsbFvBuilder";
export class RcsbFv3DSequenceIdentity extends RcsbFv3DAbstract {
    constructor(params) {
        var _a, _b;
        const elementId = (_a = params.elementId) !== null && _a !== void 0 ? _a : uniqid("RcsbFv3D_");
        const alignmentResponseContainer = new DataContainer();
        super({
            elementId,
            sequenceConfig: {
                title: params.config.title,
                subtitle: params.config.subtitle,
                config: {
                    rcsbId: params.config.groupId,
                    additionalConfig: params.additionalConfig,
                    pfvParams: {
                        id: params.config.groupId,
                        pfvArgs: [params.config.groupId, params.config.query],
                        buildMsaAlignmentFv: buildSequenceIdentityAlignmentFv,
                        alignmentResponseContainer
                    },
                    buildPfvOnMount: true,
                    pfvManagerFactory: new MsaPfvManagerFactory(),
                    callbackManagerFactory: new MsaCallbackManagerFactory({
                        pluginLoadParamsDefinition,
                        alignmentResponseContainer
                    }),
                    additionalContent: (props) => (_jsx(HelpLinkComponent, Object.assign({}, props, { helpHref: "/docs/grouping-structures/groups-1d-3d-alignment" })))
                }
            },
            structureConfig: {
                structureViewerConfig: {
                    viewerElement: RcsbFvStructure.componentId(elementId),
                    viewerProps: (_b = params.molstarProps) !== null && _b !== void 0 ? _b : {}
                }
            },
            structureViewer: new StructureViewer(new MolstarManagerFactory(getModelIdFromTrajectory)),
            structureViewerBehaviourObserver: new MsaBehaviourObserver(new MolstarAlignmentLoader())
        });
    }
}
const pluginLoadParamsDefinition = (entryId) => ({
    loadMethod: LoadMethod.loadPdbId,
    loadParams: {
        entryId
    }
});
