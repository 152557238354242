import { RcsbFvStructure } from "../RcsbFvStructure/RcsbFvStructure";
import uniqid from "uniqid";
import { StructureViewer } from "../RcsbFvStructure/StructureViewers/StructureViewer";
import { MolstarManagerFactory } from "../RcsbFvStructure/StructureViewers/MolstarViewer/MolstarManagerFactory";
import { NullBehaviourObserver } from "../RcsbFvStructure/StructureViewerBehaviour/NullBehaviour";
import { MolstarTools } from "../RcsbFvStructure/StructureViewers/MolstarViewer/MolstarUtils/MolstarTools";
var getModelIdFromTrajectory = MolstarTools.getModelIdFromTrajectory;
import { RcsbFv3DCustomAbstract } from "./RcsbFv3DCustomAbstract";
export class RcsbFv3DCustom extends RcsbFv3DCustomAbstract {
    constructor(params) {
        var _a;
        const elementId = (_a = params.elementId) !== null && _a !== void 0 ? _a : uniqid("RcsbFv3D_");
        super({
            elementId: elementId,
            structureConfig: {
                loadConfig: params.structurePanelConfig.loadConfig,
                structureViewerConfig: Object.assign(Object.assign({}, params.structurePanelConfig.structureViewerConfig), { viewerElement: RcsbFvStructure.componentId(elementId) })
            },
            sequenceConfig: Object.assign({}, params.sequencePanelConfig),
            structureViewer: new StructureViewer(new MolstarManagerFactory(getModelIdFromTrajectory)),
            structureViewerBehaviourObserver: new NullBehaviourObserver(),
            cssConfig: params.cssConfig
        });
    }
}
