import { __awaiter } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
export class MsaUiSortComponent extends React.Component {
    render() {
        return _jsx("div", { title: "PIN selected entities to top", onClick: () => this.click(), style: { cursor: "pointer" }, children: "PIN ACTIVE" });
    }
    click() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const targets = (_a = this.props.rcsbFvContainer.get()) === null || _a === void 0 ? void 0 : _a.getFv().getBoardData().map((d) => { var _a; return (_a = d.metadata) === null || _a === void 0 ? void 0 : _a.targetId; }).filter((d) => Boolean(d));
            if (!targets)
                return;
            const headerShift = (_b = this.props.rcsbFvContainer.get()) === null || _b === void 0 ? void 0 : _b.getFv().getBoardData().findIndex((d) => { var _a; return (_a = d.metadata) === null || _a === void 0 ? void 0 : _a.targetId; });
            if (typeof headerShift === "undefined" || headerShift < 0)
                return;
            const threshold = targets.findIndex(target => !this.props.stateManager.assemblyModelSate.getMap().has(target));
            if (threshold < 0)
                return;
            const toMove = targets.reduce((prev, curr, currIndex) => { if (this.props.stateManager.assemblyModelSate.getMap().has(curr) && currIndex > threshold)
                prev.push(currIndex); return prev; }, []);
            for (const [n, i] of toMove.map((n, i) => [n, threshold + i])) {
                yield ((_d = (_c = this.props.rcsbFvContainer.get()) === null || _c === void 0 ? void 0 : _c.getFv()) === null || _d === void 0 ? void 0 : _d.moveTrack(n + headerShift, i + headerShift));
            }
        });
    }
}
